import {memo} from 'react';
import {Button, Col, Row} from 'reactstrap';

type Props = {
  loading: boolean
  onReturnClick: () => void
  onAcceptClick: () => void
  shouldHideAcceptButton?: boolean
}

const StateFormButtons = ({
                            loading,
                            onReturnClick,
                            onAcceptClick,
                            shouldHideAcceptButton = false
                          }: Props) => {
  return <Row>
    <Col className="d-flex justify-content-end">
      <Button className="mr-2"
              color="danger"
              onClick={onReturnClick}
              disabled={loading}>
        Return
      </Button>
      {!shouldHideAcceptButton && <Button color="primary"
                                          onClick={onAcceptClick}
                                          disabled={loading}>
        Accept
      </Button>}
    </Col>
  </Row>;
};

export default memo(StateFormButtons);