import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row, Table} from 'reactstrap';
import {Formik} from 'formik';
import {useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  FormikCheckboxGroup,
  FormikInput,
  ProgressIndicator,
  TabNav,
  useAlerts,
  useTabNav,
  withTabNav
} from '@reasoncorp/kyber-js';

import {monitorStudiesApi} from '../../api/mega';
import * as messages from '../../messages';
import {formatDecimal, formatInteger} from '../../utils';
import {monitorStudySchema} from '../../schemas/mega';
import {mega} from '../../types';
import {MonitorStudyReportLocalUnit} from '../../types/mega';
import {useUnsavedChangesWarning} from '../../hooks';
import {UnsavedChangesWarningModal} from '../../components/shared';

const tabs = [
  {value: 'allUnits', displayValue: 'All Units'},
  {value: 'unitStudies', displayValue: 'Unit Studies'}
];

const MonitorStudy = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const {selectedTab} = useTabNav();
  const {hasUnsavedChanges, setHasUnsavedChanges} = useUnsavedChangesWarning();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [monitorStudyReport, setMonitorStudyReport] = useState<mega.MonitorStudyReport>(undefined);
  const [allUnitsData, setAllUnitsData] = useState<mega.MonitorStudyReportLocalUnit[]>([]);
  const [selectedUnitsData, setSelectedUnitsData] = useState<mega.MonitorStudyReportLocalUnit[]>([]);
  const [monitorStudiesRequest, setMonitorStudiesRequest] = useState<mega.MonitorStudiesRequest>({});
  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: monitorStudyReport?.county?.displayNameWithType ?? '', active: true}
  ]), [
    year,
    countyId,
    monitorStudyReport
  ]);

  const mapValues = useCallback((localUnits: mega.MonitorStudyReportLocalUnit[]) => {
    return {
      localUnits: localUnits.map(localUnit => {
        monitorStudiesRequest[localUnit.localUnitId] = monitorStudiesRequest[localUnit.localUnitId] || {};
        monitorStudiesRequest[localUnit.localUnitId].comments = (monitorStudiesRequest[localUnit.localUnitId].comments !== null &&
          monitorStudiesRequest[localUnit.localUnitId].comments !== undefined) ? monitorStudiesRequest[localUnit.localUnitId].comments : localUnit.comments;
        monitorStudiesRequest[localUnit.localUnitId].selected = (monitorStudiesRequest[localUnit.localUnitId].selected !== null &&
          monitorStudiesRequest[localUnit.localUnitId].selected !== undefined) ?
          monitorStudiesRequest[localUnit.localUnitId].selected : localUnit.selected;
        return {
          selected: localUnit.selected || false,
          comments: localUnit.comments || ''
        };
      })
    };
  }, [
    monitorStudiesRequest
  ]);

  useEffect(() => {
    const loadMonitorStudyReport = async () => {
      try {
        const monitorStudyReport = await monitorStudiesApi.find(countyId, year);
        setMonitorStudyReport(monitorStudyReport);
        setAllUnitsData(monitorStudyReport?.localUnits ?? []);
        setSelectedUnitsData(monitorStudyReport?.localUnits?.filter((item: mega.MonitorStudyReportLocalUnit) => item.selected) ?? []);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadMonitorStudyReport();
  }, [countyId, year, showErrorAlert]);

  const recalculateTotals = useCallback((selectedLocalUnits: mega.MonitorStudyReportLocalUnit[]) => {
    const selectedTotals = {
      numberOfParcels: 0,
      startingAssessedValue: 0,
      cedSampleSizeParcels: 0,
      cedSampleSizeAssessedValue: 0,
      cedTrueCashValueProjection: 0,
      psdSampleSizeParcels: 0,
      psdSampleSizeAssessedValue: 0,
      psdTrueCashValueProjection: 0,
      cedSampleSizePercentParcels: 0,
      cedSampleSizePercentAssessedValue: 0,
      psdSampleSizePercentParcels: 0,
      psdSampleSizePercentAssessedValue: 0,
      percentDifference: 0
    };

    selectedLocalUnits.forEach(localUnit => {
      selectedTotals.numberOfParcels += Number.isInteger(localUnit.numberOfParcels) ? localUnit.numberOfParcels : 0;
      selectedTotals.startingAssessedValue += Number.isInteger(localUnit.startingAssessedValue) ? localUnit.startingAssessedValue : 0;
      selectedTotals.cedSampleSizeParcels += Number(Number.isInteger(localUnit.ced.sampleSizeParcels) ? localUnit.ced.sampleSizeParcels : 0);
      selectedTotals.cedSampleSizeAssessedValue += Number(Number.isInteger(localUnit.ced.sampleSizeAssessedValue) ? localUnit.ced.sampleSizeAssessedValue : 0);
      selectedTotals.cedTrueCashValueProjection += Number(Number.isInteger(localUnit.ced.trueCashValueProjection) ? localUnit.ced.trueCashValueProjection : 0);
      selectedTotals.psdSampleSizeParcels += Number(Number.isInteger(localUnit.psd.sampleSizeParcels) ? localUnit.psd.sampleSizeParcels : 0);
      selectedTotals.psdSampleSizeAssessedValue += Number(Number.isInteger(localUnit.psd.sampleSizeAssessedValue) ? localUnit.psd.sampleSizeAssessedValue : 0);
      selectedTotals.psdTrueCashValueProjection += Number(Number.isInteger(localUnit.psd.trueCashValueProjection) ? localUnit.psd.trueCashValueProjection : 0);
    });

    selectedTotals.cedSampleSizePercentParcels = Number(selectedTotals.numberOfParcels !== 0 ?
      selectedTotals.cedSampleSizeParcels / selectedTotals.numberOfParcels : 0);
    selectedTotals.cedSampleSizePercentAssessedValue = Number(selectedTotals.numberOfParcels !== 0 ?
      selectedTotals.cedSampleSizeAssessedValue / selectedTotals.startingAssessedValue : 0);
    selectedTotals.psdSampleSizePercentParcels = Number(selectedTotals.numberOfParcels !== 0 ?
      selectedTotals.psdSampleSizeParcels / selectedTotals.numberOfParcels : 0);
    selectedTotals.psdSampleSizePercentAssessedValue = selectedTotals.numberOfParcels !== 0 ?
      selectedTotals.psdSampleSizeAssessedValue / selectedTotals.startingAssessedValue : 0;

    if (selectedTotals.psdTrueCashValueProjection !== null && selectedTotals.cedTrueCashValueProjection !== null && selectedTotals.cedTrueCashValueProjection !== 0) {
      selectedTotals.percentDifference = (selectedTotals.psdTrueCashValueProjection / selectedTotals.cedTrueCashValueProjection) - 1;
    } else {
      selectedTotals.percentDifference = 0;
    }

    (setMonitorStudyReport as any)({...monitorStudyReport, selectedTotals});
  }, [
    monitorStudyReport
  ]);

  const handleToggleSelected = useCallback((item: mega.MonitorStudyReportLocalUnit, index: number) => {
    setHasUnsavedChanges(true);
    const monitorStudiesRequestCopy = {...monitorStudiesRequest};
    const allUnitsDataCopy = [...allUnitsData];
    monitorStudiesRequestCopy[item.localUnitId].selected = !monitorStudiesRequestCopy[item.localUnitId].selected;
    allUnitsDataCopy[index].selected = monitorStudiesRequestCopy[item.localUnitId].selected as boolean;
    const selectedLocalUnits = allUnitsDataCopy.filter((item: mega.MonitorStudyReportLocalUnit) => item.selected);
    setAllUnitsData(allUnitsDataCopy);
    setSelectedUnitsData(selectedLocalUnits);
    setMonitorStudiesRequest(monitorStudiesRequestCopy);
    recalculateTotals(selectedLocalUnits);
  }, [
    setHasUnsavedChanges,
    allUnitsData,
    monitorStudiesRequest,
    recalculateTotals
  ]);

  const handleUpdateComments = useCallback((item: mega.MonitorStudyReportLocalUnit, comments: string) => {
    setHasUnsavedChanges(true);
    const monitorStudiesRequestCopy = {...monitorStudiesRequest};
    monitorStudiesRequestCopy[item.localUnitId].comments = comments;
    setMonitorStudiesRequest(monitorStudiesRequestCopy);
  }, [
    setHasUnsavedChanges,
    monitorStudiesRequest
  ]);

  const handleSave = useCallback(async (setSubmitting: (isSubmitting: boolean) => void,
                                        afterSave?: () => void) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await monitorStudiesApi.update(countyId, year, monitorStudiesRequest);
      showSuccessAlert(messages.REPORT_SAVE_SUCCESSFUL);
      setSubmitting(false);

      if (afterSave) {
        afterSave();
      }
    } catch {
      showErrorAlert(messages.REPORT_SAVE_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyId,
    loadingState,
    monitorStudiesRequest,
    showErrorAlert,
    showSuccessAlert,
    year
  ]);

  const allUnitsIndexes = useMemo(() => {
    const indexes: {[localUnitId: number]: number} = {};
    for (let i = 0; i < allUnitsData.length; i++) {
      indexes[allUnitsData[i].localUnitId] = i;
    }

    return indexes;
  }, [
    allUnitsData
  ]);

  const unitData = useMemo(() => {
    return selectedTab === 'allUnits' ? allUnitsData : selectedUnitsData;
  }, [
    selectedTab,
    allUnitsData,
    selectedUnitsData
  ]);

  const totals = useMemo(() => {
    return selectedTab === 'allUnits' ? monitorStudyReport?.allTotals : monitorStudyReport?.selectedTotals;
  }, [
    monitorStudyReport,
    selectedTab
  ]);

  const renderRow = useMemo(() => (item: MonitorStudyReportLocalUnit) => {
    const index = allUnitsIndexes[item.localUnitId];
    return <tr key={item.localUnitId}>
      <td className="align-middle text-center">
        <FormikCheckboxGroup formGroupClass="mb-0"
                             checkboxes={[{
                               name: `localUnits[${index}].selected`,
                               ariaLabel: 'Selected',
                               disabled: selectedUnitsData.length === 7 && !monitorStudiesRequest[item.localUnitId].selected,
                               onChange: () => handleToggleSelected(item, index)
                             }]}/>
      </td>
      <td className="text-center align-middle font-weight-bold text-primary">
        {item.localUnitId}
      </td>
      <td className="text-left text-nowrap align-middle font-weight-bold text-primary">
        {item.localUnitName}
      </td>
      <td className="text-center align-middle">
        {formatInteger(item.startingAssessedValue)}
      </td>
      <td className="text-center align-middle">
        {formatInteger(item.numberOfParcels)}
      </td>
      <td className="text-center align-middle border-left-3">
        {item.ced.studyType}
      </td>
      <td className="text-center align-middle">
        {formatInteger(item.ced.sampleSizeParcels)}
      </td>
      <td className="text-center align-middle">
        {formatInteger(item.ced.sampleSizeAssessedValue)}
      </td>
      <td className="text-center align-middle">
        {formatDecimal(item.ced.sampleSizePercentParcels, 1, true)}
      </td>
      <td className="text-center align-middle">
        {formatDecimal(item.ced.sampleSizePercentAssessedValue, 1, true)}
      </td>
      <td className="text-center align-middle">
        {formatDecimal(item.ced.salesRatio, 2, true)}
      </td>
      <td className="text-center align-middle">
        {formatInteger(item.ced.trueCashValueProjection)}
      </td>
      <td className="bg-light-teal text-center align-middle border-left-3">
        {item.psd.studyType}
      </td>
      <td className="bg-light-teal text-center align-middle">
        {formatInteger(item.psd.sampleSizeParcels)}
      </td>
      <td className="bg-light-teal text-center align-middle">
        {formatInteger(item.psd.sampleSizeAssessedValue)}
      </td>
      <td className="bg-light-teal text-center align-middle">
        {formatDecimal(item.psd.sampleSizePercentParcels, 1, true)}
      </td>
      <td className="bg-light-teal text-center align-middle">
        {formatDecimal(item.psd.sampleSizePercentAssessedValue, 1, true)}
      </td>
      <td className="bg-light-teal text-center align-middle">
        {formatDecimal(item.psd.salesRatio, 2, true)}
      </td>
      <td className="bg-light-teal text-center align-middle">
        {formatInteger(item.psd.trueCashValueProjection)}
      </td>
      <td className="border-left-3 text-center align-middle bg-light-teal">
        {formatDecimal(item.percentDifference, 2, true)}
      </td>
      <td className="text-center align-middle bg-light-teal">
        <FormikInput name={`localUnits[${index}].comments`}
                     ariaLabel="Comments"
                     maxLength="200"
                     disableFloatingLabel={true}
                     className="shaded-comments"
                     formGroupClass="mb-0"
                     onChange={(e) => handleUpdateComments(item, e.target.value)}/>
      </td>
    </tr>;
  }, [
    handleUpdateComments,
    allUnitsIndexes,
    handleToggleSelected,
    monitorStudiesRequest,
    selectedUnitsData.length
  ]);

  return <Container fluid className="MonitorStudy">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && monitorStudyReport && totals && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Formik validationSchema={monitorStudySchema}
              initialValues={{...mapValues(allUnitsData)}}
              enableReinitialize={true}
              validateOnMount={true}
              onSubmit={(_, actions) => handleSave(actions.setSubmitting)}>
        {(formikProps) => (<>
          <Card className="mb-4">
            <CardHeader>
              Monitor Study
            </CardHeader>
            <CardHeader className="nav-tabs-header">
              <TabNav/>
            </CardHeader>
            <div className="table-fixed SplitTable">
              <Table bordered responsive>
                <thead>
                  <tr className="bg-light">
                    <th colSpan={5} className="bg-light"/>
                    <th className="bg-light text-primary text-center align-middle border-left-3" colSpan={7}>CED</th>
                    <th className="bg-light-teal text-primary text-center align-middle border-left-3" colSpan={7}>PSD</th>
                    <th colSpan={2} className="bg-light-teal border-left-3"/>
                  </tr>
                  <tr className="bg-light position-top-50">
                    <th className="text-primary text-center align-middle bg-light">Select</th>
                    <th className="text-primary text-center align-middle bg-light">Unit #</th>
                    <th className="text-primary text-left align-middle bg-light">Township or City</th>
                    <th className="text-primary text-center align-middle bg-light">Starting Assessed Value</th>
                    <th className="text-primary text-center align-middle bg-light"># of Parcels</th>
                    <th className="text-primary text-center align-middle bg-light border-left-3">Study Type</th>
                    <th className="text-primary text-center align-middle bg-light">Sample Size Parcels</th>
                    <th className="text-primary text-center align-middle bg-light">Sample Size Assessed</th>
                    <th className="text-primary text-center align-middle bg-light">Sample % Parcels</th>
                    <th className="text-primary text-center align-middle bg-light">Sample % Assessed</th>
                    <th className="text-primary text-center align-middle bg-light">Sales Ratio</th>
                    <th className="text-primary text-center align-middle bg-light">{`${Number(year) + 1} TCV Projection`}</th>
                    <th className="text-primary text-center align-middle border-left-3 bg-light-teal">CED Study Type</th>
                    <th className="text-primary text-center align-middle bg-light-teal">Sample Size Parcels</th>
                    <th className="text-primary text-center align-middle bg-light-teal">Sample Size Assessed</th>
                    <th className="text-primary text-center align-middle bg-light-teal">Sample % Parcels</th>
                    <th className="text-primary text-center align-middle bg-light-teal">Sample % Assessed</th>
                    <th className="text-primary text-center align-middle bg-light-teal">Sales Ratio</th>
                    <th className="text-primary text-center align-middle bg-light-teal">{`${Number(year) + 1} TCV Projection`}</th>
                    <th className="text-primary text-center align-middle border-left-3 bg-light-teal">% Difference</th>
                    <th className="text-primary text-center align-middle bg-light-teal">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {(!unitData || unitData.length === 0) && <tr>
                    <td colSpan={21}>
                      No Units Selected.
                    </td>
                  </tr>}
                  {unitData.map(renderRow)}
                </tbody>
                <tfoot>
                  <tr key={`${selectedTab}-totals`} className="bg-light font-weight-bold">
                    <td className="bg-light text-center align-middle text-primary">Totals</td>
                    <td className="bg-light"/>
                    <td className="bg-light"/>
                    <td className="bg-light text-center align-middle">
                      {formatInteger(totals.startingAssessedValue)}
                    </td>
                    <td className="bg-light text-center align-middle">
                      {formatInteger(totals.numberOfParcels)}
                    </td>
                    <td className="bg-light border-left-3"/>
                    <td className="bg-light text-center align-middle">
                      {formatInteger(totals.cedSampleSizeParcels)}
                    </td>
                    <td className="bg-light text-center align-middle">
                      {formatInteger(totals.cedSampleSizeAssessedValue)}
                    </td>
                    <td className="bg-light text-center align-middle">
                      {formatDecimal(totals.cedSampleSizePercentParcels, 1, true)}
                    </td>
                    <td className="bg-light text-center align-middle">
                      {formatDecimal(totals.cedSampleSizePercentAssessedValue, 1, true)}
                    </td>
                    <td className="bg-light"/>
                    <td className="bg-light text-center align-middle">
                      {formatInteger(totals.cedTrueCashValueProjection)}
                    </td>
                    <td className="border-left-3 bg-light-teal"/>
                    <td className="text-center align-middle bg-light-teal">
                      {formatInteger(totals.psdSampleSizeParcels)}
                    </td>
                    <td className="text-center align-middle bg-light-teal">
                      {formatInteger(totals.psdSampleSizeAssessedValue)}
                    </td>
                    <td className="text-center align-middle bg-light-teal">
                      {formatDecimal(totals.psdSampleSizePercentParcels, 1, true)}
                    </td>
                    <td className="text-center align-middle bg-light-teal">
                      {formatDecimal(totals.psdSampleSizePercentAssessedValue, 1, true)}
                    </td>
                    <td className="bg-light-teal"/>
                    <td className="text-center align-middle bg-light-teal">
                      {formatInteger(totals.psdTrueCashValueProjection)}
                    </td>
                    <td className="bg-light-teal text-center align-middle border-left-3">
                      {formatDecimal(totals.percentDifference, 2, true)}
                    </td>
                    <td className="bg-light-teal"/>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Card>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button color="success"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.isValid || formikProps.isSubmitting || !hasUnsavedChanges}>
                Save
              </Button>
            </Col>
          </Row>

          <UnsavedChangesWarningModal onSave={(afterSave) => handleSave(formikProps.setSubmitting, afterSave)}/>
        </>)}
      </Formik>
    </>}
  </Container>;
};

export default withTabNav(MonitorStudy, {tabs});