import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, CustomInput, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  BreadcrumbsNav,
  MiSuiteRole,
  ProgressIndicator,
  ProgressModal,
  ssoUtils,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {formImportApi, localUnitFormApi, stateFormApi} from '../api';
import * as messages from '../messages';
import {Form4014a, Form4015, Form4015a, Form4017, Form4018, Form4022, Form4023} from './forms';
import {JsonImportModal, LockModal, ResetModal} from '../components/shared';
import {FormStatus, FormType} from '../enum';
import {AmendmentRequest, forms, JsonImportUploadRequest, LocalUnitFormDto, ReturnRequest} from '../types';
import {usePortal, useUnsavedChangesWarning} from '../hooks';
import {LocalUnitFormData} from '../types/forms';

type Props = {
  isStateViewLocalUnit4022?: boolean
}

type Params = {
  countyId: string
  localUnitId: string
  year: string
  countyFormId: string
  localUnitFormId: string
};

const LocalUnitForm = ({
                         isStateViewLocalUnit4022 = false
                       }: Props) => {
  const {
    countyId,
    localUnitFormId,
    localUnitId,
    countyFormId,
    year
  } = useParams() as Params;
  const {permissions, currentUser} = useUserContext();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {hasUnsavedChanges, setHasUnsavedChanges} = useUnsavedChangesWarning();
  const [loadingState, setLoadingState] = useState({
    loading: true,
    processing: false,
    loadError: false,
    saving: false
  });
  const [localUnitForm, setLocalUnitForm] = useState<LocalUnitFormDto | undefined>(undefined);
  const [resetModalIsOpen, setResetModalIsOpen] = useState(false);
  const [renderCount, setRenderCount] = useState(1);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [lockModalIsOpen, setLockModalIsOpen] = useState(false);
  const [
    classificationStudyYears,
    setClassificationStudyYears
  ] = useState<forms.ClassificationStudyYears | null>(null);
  const [progressModalActionText, setProgressModalActionText] = useState({
    header: 'Saving Form',
    action: 'saved'
  });
  const navigate = useNavigate();
  const [importErrorMessages, setImportErrorMessages] = useState<string[]>([]);
  const {isCountyPortal, isLocalUnitPortal, isStatePortal} = usePortal();

  const shouldSave = useMemo(() => {
    const formsAlwaysSavedBeforeSubmit = [
      FormType.FORM_4018_P_R,
      FormType.FORM_4023
    ];

    return hasUnsavedChanges ||
      localUnitForm?.status === FormStatus.NOT_STARTED ||
      formsAlwaysSavedBeforeSubmit.includes(localUnitForm?.formType as FormType);
  }, [
    localUnitForm,
    hasUnsavedChanges
  ]);

  const isLocalUnitUser = useMemo(() => {
    const isCurrentLocalUnitUser = ssoUtils.hasJurisdictionCanonicalIdAndRole(
        currentUser,
        Number(localUnitId),
        MiSuiteRole.ASSESSOR_OF_RECORD
      ) ||
      ssoUtils.hasJurisdictionCanonicalIdAndRole(
        currentUser,
        Number(localUnitId),
        MiSuiteRole.ASSESSING_DEPUTY
      ) ||
      ssoUtils.hasJurisdictionCanonicalIdAndRole(
        currentUser,
        Number(localUnitId),
        MiSuiteRole.ASSESSING_CLERK
      );

    return isLocalUnitPortal && isCurrentLocalUnitUser;
  }, [
    currentUser,
    localUnitId,
    isLocalUnitPortal
  ]);

  const isCountyUser = useMemo(() => {
    const isCurrentCountyUser = ssoUtils.hasJurisdictionCanonicalIdAndRole(
        currentUser,
        Number(countyId),
        MiSuiteRole.EQUALIZATION_DIRECTOR
      ) ||
      ssoUtils.hasJurisdictionCanonicalIdAndRole(
        currentUser,
        Number(countyId),
        MiSuiteRole.EQUALIZATION_DEPUTY
      );

    return isCurrentCountyUser && isCountyPortal;
  }, [
    currentUser,
    countyId,
    isCountyPortal
  ]);

  const isLocalUnitView4022 = useMemo(() => {
    return isLocalUnitUser && (
      localUnitForm?.formType === FormType.FORM_4022_SA ||
      localUnitForm?.formType === FormType.FORM_4022_AV
    );
  }, [
    localUnitForm,
    isLocalUnitUser
  ]);

  const breadcrumbs = useMemo(() => {
    if (isStatePortal) {
      return [{
        text: 'State  Dashboard',
        icon: 'home' as const,
        route: `/state-portal/forms/${year}`
      }, {
        text: localUnitForm?.countyDisplayName ?? '',
        route: isStateViewLocalUnit4022 ?
          `/state-portal/${countyId}/${year}/forms/${countyFormId}/local-units/forms-local-unit` :
          `/state-portal/${countyId}/${year}/forms/${countyFormId}/local-units/forms`
      }, {
        text: localUnitForm?.localUnitDisplayName ?? '',
        active: true
      }];
    } else if (isLocalUnitUser) {
      return [{
        text: 'Local Unit Dashboard',
        icon: 'home' as const,
        route: `/local-unit-portal/${localUnitId}/${year}`
      }, {
        text: localUnitForm?.localUnitDisplayName ?? '',
        active: true
      }];
    } else {
      return [{
        text: 'County Dashboard',
        icon: 'home' as const,
        route: `/county-portal/${countyId}/${year}`
      }, {
        text: localUnitForm?.countyDisplayName ?? '',
        route: `/county-portal/${countyId}/${year}/forms/${countyFormId}/local-units`
      }, {
        text: localUnitForm?.localUnitDisplayName ?? '',
        active: true
      }];
    }
  }, [
    countyFormId,
    countyId,
    isStateViewLocalUnit4022,
    isLocalUnitUser,
    localUnitForm,
    localUnitId,
    isStatePortal,
    year
  ]);

  // Get next to last breadcrumb for the redirect URL
  const redirectUrl = useMemo(() => {
    return breadcrumbs[breadcrumbs.length - 2].route as string;
  }, [
    breadcrumbs
  ]);

  const toggleResetModal = useCallback(() => {
    setResetModalIsOpen(!resetModalIsOpen);
  }, [
    resetModalIsOpen
  ]);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        const localUnitForm = isStatePortal
          ? await stateFormApi.findLocalUnitForm(countyId, localUnitId, localUnitFormId)
          : await localUnitFormApi.find(localUnitId, localUnitFormId);
        if (['FORM_4015', 'FORM_4017_4047'].includes(localUnitForm.formType) && localUnitForm.data !== null) {
          const formDataWithStudyYears = localUnitForm.data as {
            [otherField: string]: any,
            classificationStudyYears: forms.ClassificationStudyYears
          };
          setClassificationStudyYears(formDataWithStudyYears.classificationStudyYears);
        }

        setLocalUnitForm(localUnitForm);
        setRenderCount((prevCount) => prevCount + 1);
      } catch (e) {
        setLoadingState((loadingState) => {
          return {...loadingState, loadError: true};
        });
        showErrorAlert(messages.API_FAILURE, true);
      } finally {
        setLoadingState((loadingState) => {
          return {...loadingState, loading: false};
        });
      }
    };

    void initialLoad();
  }, [
    countyId,
    localUnitId,
    localUnitFormId,
    isStatePortal,
    showErrorAlert,
    setRenderCount
  ]);

  const handleReset = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      const form = await localUnitFormApi.reset(localUnitId, localUnitFormId, isLocalUnitUser);

      if (['FORM_4015', 'FORM_4017_4047'].includes(form.formType) && form.data !== null) {
        setClassificationStudyYears({});
      }

      setLocalUnitForm(form);
      setRenderCount(renderCount + 1);
      setResetModalIsOpen(!setResetModalIsOpen);
      showSuccessAlert(messages.FORM_RESET_SUCCESSFUL);
    } catch {
      showErrorAlert(messages.FORM_RESET_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    localUnitFormId,
    localUnitId,
    renderCount,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    setResetModalIsOpen,
    isLocalUnitUser
  ]);

  const handleSave = useCallback(async (localUnitFormData: forms.LocalUnitFormData,
                                        afterSave?: () => void) => {
    setLoadingState({...loadingState, saving: true, processing: true});
    setProgressModalActionText({
      header: 'Saving Form',
      action: 'saved'
    });

    try {
      const localUnitForm = await localUnitFormApi.save(
        localUnitId,
        localUnitFormId,
        localUnitFormData,
        isLocalUnitUser
      );
      setLocalUnitForm(localUnitForm);
      showSuccessAlert(messages.FORM_SAVE_SUCCESSFUL);
      setHasUnsavedChanges(false);

      if (afterSave) {
        afterSave();
      }
    } catch (error) {
      showErrorAlert(messages.FORM_SAVE_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, saving: false, processing: false});
    }
  }, [
    setHasUnsavedChanges,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    isLocalUnitUser
  ]);

  const handleSend4015ToReview = useCallback(async (localUnitFormData: forms.LocalUnitFormData) => {
    setLoadingState({...loadingState, processing: true, saving: hasUnsavedChanges});
    setProgressModalActionText({
      header: 'Sending to Local Unit',
      action: 'sent'
    });

    try {
      if (hasUnsavedChanges) {
        await localUnitFormApi.save(localUnitId, localUnitFormId, localUnitFormData, isLocalUnitUser);
      }

      const form = await localUnitFormApi.send4015ToReview(localUnitId, localUnitFormId);
      setLocalUnitForm(form);
      showSuccessAlert(messages.FORM_SENT_TO_LOCAL_UNIT_SUCCESSFUL);
      setHasUnsavedChanges(false);
      navigate(redirectUrl);
    } catch (error) {
      showErrorAlert(messages.FORM_SENT_TO_LOCAL_UNIT_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    setHasUnsavedChanges,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    isLocalUnitUser,
    hasUnsavedChanges,
    navigate,
    redirectUrl
  ]);

  const handleSubmit = useCallback(async (localUnitFormData: forms.LocalUnitFormData) => {
    try {
      // Open progress modal if there are unsaved changes
      setLoadingState({...loadingState, processing: true, saving: shouldSave});
      setProgressModalActionText({
        header: 'Submitting Form',
        action: 'submitted'
      });
      if (shouldSave) {
        await localUnitFormApi.save(localUnitId, localUnitFormId, localUnitFormData, isLocalUnitUser);
      }

      const form = await localUnitFormApi.submit(localUnitId, localUnitFormId);
      setLocalUnitForm(form);
      showSuccessAlert(messages.FORM_SUBMIT_SUCCESSFUL);
      setHasUnsavedChanges(false);
      navigate(redirectUrl);
    } catch (error) {
      showErrorAlert(messages.FORM_SUBMIT_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false, saving: false});
    }
  }, [
    setHasUnsavedChanges,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    isLocalUnitUser,
    shouldSave,
    navigate,
    redirectUrl
  ]);

  const handleSubmitToCounty = useCallback(async (localUnitFormData: forms.LocalUnitFormData) => {
    setLoadingState({...loadingState, processing: true});

    try {
      await localUnitFormApi.save(localUnitId, localUnitFormId, localUnitFormData, true);
      const form = await localUnitFormApi.submitToCounty(localUnitId, localUnitFormId);
      setLocalUnitForm(form);
      showSuccessAlert(messages.FORM_SUBMIT_SUCCESSFUL);
      setHasUnsavedChanges(false);
      navigate(redirectUrl);
    } catch (error) {
      showErrorAlert(messages.FORM_SUBMIT_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    setHasUnsavedChanges,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    redirectUrl,
    navigate
  ]);

  const handleResubmit = useCallback(async (localUnitFormData: forms.LocalUnitFormData) => {
    try {
      // Open progress modal if there are unsaved changes
      setLoadingState({...loadingState, processing: true, saving: shouldSave});
      setProgressModalActionText({
        header: 'Resubmitting Form',
        action: 'resubmitted'
      });

      if (shouldSave) {
        await localUnitFormApi.save(localUnitId, localUnitFormId, localUnitFormData, isLocalUnitUser);
      }

      const localUnitForm = await localUnitFormApi.submit(localUnitId, localUnitFormId);
      setLocalUnitForm(localUnitForm);
      showSuccessAlert(messages.FORM_RESUBMIT_SUCCESSFUL);
      setHasUnsavedChanges(false);
      navigate(redirectUrl);
    } catch (error) {
      showErrorAlert(messages.FORM_RESUBMIT_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false, saving: false});
    }
  }, [
    setHasUnsavedChanges,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    isLocalUnitUser,
    shouldSave,
    navigate,
    redirectUrl
  ]);

  const submitButtonText = useMemo(() => {
    if (!localUnitForm) {
      return 'Submit';
    }

    const {
      isOverdue,
      formSubmissions,
      formReturns,
      status
    } = localUnitForm as LocalUnitFormDto;
    const hasBeenSubmitted = formSubmissions.length >= 1;
    const formHasBeenReviewed = status === FormStatus.ACCEPTED || formReturns.length >= 1;

    if (hasBeenSubmitted && (isOverdue || formHasBeenReviewed)) {
      return 'Amend';
    } else if (hasBeenSubmitted && !isOverdue) {
      return 'Resubmit';
    } else {
      return 'Submit';
    }
  }, [
    localUnitForm
  ]);

  const handleAmend = useCallback(async (amendmentRequest: AmendmentRequest,
                                         localUnitFormData: forms.LocalUnitFormData) => {
    try {
      // Open progress modal if there are unsaved changes
      setLoadingState({...loadingState, processing: true, saving: shouldSave});
      setProgressModalActionText({
        header: 'Amending Form',
        action: 'amended'
      });

      if (shouldSave) {
        await localUnitFormApi.save(localUnitId, localUnitFormId, localUnitFormData, isLocalUnitUser);
      }

      const localUnitForm = await localUnitFormApi.amend(localUnitId, localUnitFormId, amendmentRequest);
      setLocalUnitForm(localUnitForm);
      setRenderCount(renderCount + 1);
      showSuccessAlert(messages.FORM_AMEND_SUCCESSFUL);
      setHasUnsavedChanges(false);
      navigate(redirectUrl);
    } catch (error) {
      showErrorAlert(messages.FORM_AMEND_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false, saving: false});
    }
  }, [
    setHasUnsavedChanges,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    isLocalUnitUser,
    shouldSave,
    renderCount,
    redirectUrl,
    navigate
  ]);

  const handleReturn = useCallback(async (returnRequest: ReturnRequest) => {
    setLoadingState({...loadingState, processing: true});
    try {
      const localUnitForm = await stateFormApi.returnLocalUnitForm(countyId, localUnitId, localUnitFormId, returnRequest);
      setLocalUnitForm(localUnitForm);
      setRenderCount(renderCount + 1);
      showSuccessAlert(messages.FORM_RETURN_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.FORM_RETURN_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyId,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    renderCount
  ]);

  const handleReturnToLocalUnit = useCallback(async (returnRequest: ReturnRequest) => {
    setLoadingState({...loadingState, processing: true});
    try {
      const localUnitForm = await localUnitFormApi.returnToLocalUnit(localUnitId, localUnitFormId, returnRequest);
      setLocalUnitForm(localUnitForm);
      setRenderCount(renderCount + 1);
      showSuccessAlert(messages.FORM_RETURN_SUCCESSFUL);
      setHasUnsavedChanges(false);
      navigate(redirectUrl);
    } catch {
      showErrorAlert(messages.FORM_RETURN_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    setHasUnsavedChanges,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    renderCount,
    redirectUrl,
    navigate
  ]);

  const handleAccept = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      const localUnitForm = await stateFormApi.acceptLocalUnitForm(countyId, localUnitId, localUnitFormId);
      setLocalUnitForm(localUnitForm);
      setRenderCount(renderCount + 1);
      showSuccessAlert(messages.FORM_ACCEPT_SUCCESSFUL);
    } catch {
      showErrorAlert(messages.FORM_ACCEPT_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyId,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    renderCount
  ]);

  const handleLockSave = useCallback(async (locked: boolean) => {
    setLoadingState({...loadingState, processing: true});
    setLockModalIsOpen(false);
    try {
      const localUnitForm = await stateFormApi.updateLocalUnitFormLock(countyId, localUnitId, localUnitFormId, locked);
      setLocalUnitForm(localUnitForm);
      setRenderCount(renderCount + 1);
      showSuccessAlert(locked ? messages.FORM_LOCK_SUCCESSFUL : messages.FORM_UNLOCK_SUCCESSFUL);
    } catch {
      showErrorAlert(locked ? messages.FORM_LOCK_FAILURE : messages.FORM_UNLOCK_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    renderCount,
    countyId
  ]);

  const handleImport = useCallback(async ({
                                            jsonFile,
                                            mappingKeys
                                          }: JsonImportUploadRequest,
                                          actions: FormikHelpers<JsonImportUploadRequest>) => {
    setLoadingState({...loadingState, processing: true});
    setImportErrorMessages([]);

    try {
      const formData = new FormData();
      formData.append('jsonFile', jsonFile as File);
      mappingKeys.forEach(mappingKey => formData.append('mappingKeys', mappingKey));

      const {uploadedData, errorMap} = await formImportApi.importLocalUnitForm(
        localUnitId,
        localUnitFormId,
        formData,
        isLocalUnitView4022
      );

      if (Object.keys(errorMap).length === 0 && localUnitForm) {
        setImportModalIsOpen(false);

        const uploadedDataWithExtraProperties = uploadedData as {
          [otherField: string]: any,
          properties?: {id: number}[],
          classificationStudyYears?: forms.ClassificationStudyYears
        };

        if (uploadedData && uploadedDataWithExtraProperties.properties) {
          uploadedDataWithExtraProperties.properties.map((property: {id: number}, index) => property.id = index);
        }

        if (['FORM_4015', 'FORM_4017_4047'].includes(localUnitForm.formType) && localUnitForm.data !== null) {
          uploadedDataWithExtraProperties.classificationStudyYears = {
            ...classificationStudyYears,
            ...uploadedDataWithExtraProperties.classificationStudyYears
          };
          setClassificationStudyYears(uploadedDataWithExtraProperties.classificationStudyYears);
        }

        if (isLocalUnitView4022) {
          setLocalUnitForm({
            ...localUnitForm,
            localUnitData: uploadedDataWithExtraProperties as LocalUnitFormData
          });
        } else {
          setLocalUnitForm({
            ...localUnitForm,
            data: uploadedDataWithExtraProperties as LocalUnitFormData
          });
        }

        setRenderCount(renderCount + 1);
        showSuccessAlert(messages.IMPORT_SUCCESSFUL);
        setHasUnsavedChanges(false);
        actions.resetForm();
      } else if (Object.keys(errorMap).length > 0 && localUnitForm?.formType === 'FORM_4018_P_R') {
        setImportErrorMessages(Object.values(errorMap)[0] as string[]);
        actions.setSubmitting(false);
      } else {
        actions.setFieldError('jsonFile', messages.JSON_IMPORT_INVALID);
        actions.setSubmitting(false);
      }
    } catch (e) {
      showErrorAlert(messages.IMPORT_FAILURE, true);
      setImportModalIsOpen(false);
      actions.resetForm();
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    classificationStudyYears,
    setHasUnsavedChanges,
    localUnitFormId,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    renderCount,
    localUnitForm,
    isLocalUnitView4022
  ]);

  const handleImportModalToggle = useCallback(() => {
    setImportErrorMessages([]);
    setImportModalIsOpen(false);
  }, []);

  const handleJsonImportMappingKeyToggle = useCallback(() => setImportErrorMessages([]), []);

  const shouldShowResetButton = useMemo(() => {
    return !isStatePortal &&
      !(isLocalUnitUser &&
        ['FORM_4015', 'FORM_4015A', 'FORM_4018_P_R', 'FORM_4017_4047', 'FORM_4023'].includes(localUnitForm?.formType ?? ''));
  }, [
    isStatePortal,
    isLocalUnitUser,
    localUnitForm
  ]);

  const shouldShowImportButton = useMemo(() => {
      if (!localUnitForm) {
        return false;
      }

      const formType = localUnitForm.formType;
      const wasLastSubmittedByLocalUnit = localUnitForm.hasSubmissions &&
        localUnitForm.formSubmissions[localUnitForm.formSubmissions.length - 1].wasSubmittedByLocalUnit;

      const isFormWithImportCountyPortal = [
        'FORM_4014A',
        'FORM_4015',
        'FORM_4015A',
        'FORM_4018_P_R',
        'FORM_4017_4047',
        'FORM_4022_AV',
        'FORM_4022_SA'
      ].includes(formType);

      const isForm4022 = [
        'FORM_4022_AV',
        'FORM_4022_SA'
      ].includes(formType);

      if (isStatePortal) {
        return false;
      } else if (isCountyPortal && isForm4022 && wasLastSubmittedByLocalUnit) {
        return false;
      } else if (isCountyPortal && isFormWithImportCountyPortal) {
        return true;
      } else {
        return isLocalUnitView4022;
      }
    },
    [
      isStatePortal,
      isCountyPortal,
      isLocalUnitView4022,
      localUnitForm
    ]);

  const willImportOverwriteData = useMemo(() => {
    return localUnitForm ? localUnitForm?.status !== FormStatus.NOT_STARTED : false;
  }, [
    localUnitForm
  ]);

  const shouldShowProgressModal = useMemo(() => {
    return ['FORM_4014A', 'FORM_4015', 'FORM_4015A'].includes(localUnitForm?.formType ?? '');
  }, [
    localUnitForm
  ]);

  const isLocked = useMemo(() => {
    return isLocalUnitView4022 ? localUnitForm?.lockedForLocalUnit : localUnitForm?.locked;
  }, [
    isLocalUnitView4022,
    localUnitForm
  ]);

  const shouldShowLock = useMemo(() => {
    const formType = localUnitForm?.formType ?? '';
    const status = localUnitForm?.status ?? FormStatus.NOT_STARTED;

    if (formType === 'FORM_4022_SA' || formType === 'FORM_4022_AV') {
      return !isStatePortal && isLocked;
    } else if (formType === 'FORM_4023') {
      return !isStatePortal &&
        !isLocalUnitUser &&
        localUnitForm &&
        localUnitForm.locked;
    } else if (formType === 'FORM_4015') {
      const lockedForCountyUser = !isStatePortal &&
        !isLocalUnitUser &&
        localUnitForm &&
        localUnitForm.locked;
      const lockedForLocalUnitUser = isLocalUnitUser &&
        status !== FormStatus.IN_PROGRESS &&
        status !== FormStatus.SENT_TO_REVIEW_TO_LOCAL_UNIT;

      return lockedForCountyUser || lockedForLocalUnitUser;
    } else {
      return !isStatePortal &&
        !isLocalUnitUser &&
        localUnitForm &&
        localUnitForm.locked;
    }

  }, [
    localUnitForm,
    isLocked,
    isStatePortal,
    isLocalUnitUser
  ]);

  const shouldShowLockSwitch = useMemo(() => {
    return permissions.hasCountyLockAccess &&
      isStatePortal &&
      localUnitForm &&
      !isStateViewLocalUnit4022;
  }, [
    permissions.hasCountyLockAccess,
    localUnitForm,
    isStateViewLocalUnit4022,
    isStatePortal
  ]);

  const isResetDisabled = useMemo(() => {
    return loadingState.processing ||
      (localUnitForm?.countyStatus !== FormStatus.IN_PROGRESS && localUnitForm?.status !== FormStatus.IN_PROGRESS) ||
      isLocked;
  }, [
    loadingState.processing,
    isLocked,
    localUnitForm
  ]);

  // Remove 'Ad Valorem' or 'Special Acts' from the form name used in the card header as it
  // is also in the form's description
  const scrubbedFormName = useMemo(() => {
    return localUnitForm?.name?.replace('Ad Valorem', '')?.replace('Special Acts', '');
  }, [
    localUnitForm
  ]);

  const handleViewPdfsButtonClick = useCallback(() => {
    const stateFormsPath = isStateViewLocalUnit4022 ? 'forms-local-unit' : 'forms';
    if (isStatePortal) {
      return navigate(
        `/state-portal/${countyId}/${year}/forms/${countyFormId}/local-units/${localUnitId}/${stateFormsPath}/${localUnitFormId}/pdfs`
      );
    } else if (isLocalUnitUser) {
      return navigate(
        `/local-unit-portal/${localUnitId}/${year}/forms/${localUnitFormId}/pdfs`
      );
    } else {
      return navigate(
        `/county-portal/${countyId}/${year}/forms/${countyFormId}/local-units/${localUnitId}/forms/${localUnitFormId}/pdfs`
      );
    }
  }, [
    isLocalUnitUser,
    isStateViewLocalUnit4022,
    isStatePortal,
    localUnitId,
    year,
    localUnitFormId,
    countyId,
    countyFormId,
    navigate
  ]);

  const renderFormComponent = useCallback(() => {
    const key = `${localUnitForm?.formType}-${renderCount}-${localUnitFormId}`;
    if (localUnitForm) {
      switch (localUnitForm.formType) {
        case 'FORM_4014A':
          return <Form4014a key={key}
                            loading={loadingState.processing}
                            form={localUnitForm}
                            onSave={handleSave}
                            onSubmit={handleSubmit}
                            onResubmit={handleResubmit}
                            submitButtonText={submitButtonText}
                            onAmend={handleAmend}
                            onReturn={handleReturn}
                            onAccept={handleAccept}
                            isStateUser={isStatePortal}
          />;
        case 'FORM_4015':
          return <Form4015 key={key}
                           loading={loadingState.processing}
                           form={localUnitForm}
                           onSave={handleSave}
                           onSubmit={handleSubmit}
                           onSubmitToCounty={handleSubmitToCounty}
                           onResubmit={handleResubmit}
                           onAmend={handleAmend}
                           onReturn={handleReturn}
                           onAccept={handleAccept}
                           onSendToReview={handleSend4015ToReview}
                           isLocalUnitUser={isLocalUnitUser}
                           isCountyUser={isCountyUser}
                           isStateUser={isStatePortal}
                           classificationStudyYears={classificationStudyYears}
                           setClassificationStudyYears={setClassificationStudyYears}
          />;
        case 'FORM_4015A':
          return <Form4015a key={key}
                            loading={loadingState.processing}
                            form={localUnitForm}
                            onSave={handleSave}
                            onSubmit={handleSubmit}
                            onResubmit={handleResubmit}
                            onAmend={handleAmend}
                            onReturn={handleReturn}
                            onAccept={handleAccept}
                            submitButtonText={submitButtonText}
                            isLocalUnitUser={isLocalUnitUser}
                            isStateUser={isStatePortal}
                            incrementRenderCount={() => setRenderCount(renderCount + 1)}
          />;
        case 'FORM_4017_4047':
          return <Form4017 key={key}
                           loading={loadingState.processing}
                           form={localUnitForm}
                           onSave={handleSave}
                           onSubmit={handleSubmit}
                           onResubmit={handleResubmit}
                           onAmend={handleAmend}
                           onReturn={handleReturn}
                           onAccept={handleAccept}
                           submitButtonText={submitButtonText}
                           isLocalUnitUser={isLocalUnitUser}
                           isStateUser={isStatePortal}
                           classificationStudyYears={classificationStudyYears}
                           setClassificationStudyYears={setClassificationStudyYears}
          />;
        case 'FORM_4018_P_R':
          return <Form4018 key={key}
                           loading={loadingState.processing}
                           form={localUnitForm}
                           onSave={handleSave}
                           onAmend={handleAmend}
                           onSubmit={handleSubmit}
                           onResubmit={handleResubmit}
                           onAccept={handleAccept}
                           onReturn={handleReturn}
                           submitButtonText={submitButtonText}
                           isLocalUnitUser={isLocalUnitUser}
                           isStateUser={isStatePortal}
          />;
        case 'FORM_4022_AV':
        case 'FORM_4022_SA':
          return <Form4022 key={key}
                           isStateUser={isStatePortal}
                           loading={loadingState.processing}
                           form={localUnitForm as LocalUnitFormDto}
                           isCountyUser={(permissions.isCountyUser && !isLocalUnitUser) || false}
                           isLocalUnitUser={isLocalUnitUser}
                           onSave={handleSave}
                           onAmend={handleAmend}
                           onSubmitToState={handleSubmit}
                           onSubmitToCounty={handleSubmitToCounty}
                           onResubmit={handleResubmit}
                           onReturnToLocalUnit={handleReturnToLocalUnit}
                           onReturn={handleReturn}
                           onAccept={handleAccept}
                           isStateViewLocalUnit4022={isStateViewLocalUnit4022}
          />;
        case 'FORM_4023':
          return <Form4023 key={key}
                           loading={loadingState.processing}
                           form={localUnitForm}
                           onSave={handleSave}
                           onSubmit={handleSubmit}
                           onResubmit={handleResubmit}
                           onAmend={handleAmend}
                           submitButtonText={submitButtonText}
                           onAccept={handleAccept}
                           onReturn={handleReturn}
                           isStateUser={isStatePortal}
                           isLocalUnitUser={isLocalUnitUser}
          />;
        default:
          return null;
      }
    } else {
      return null;
    }
  }, [
    localUnitFormId,
    localUnitForm,
    loadingState.processing,
    handleSave,
    handleSubmit,
    handleResubmit,
    submitButtonText,
    handleAmend,
    handleReturn,
    handleAccept,
    handleSend4015ToReview,
    permissions,
    classificationStudyYears,
    setClassificationStudyYears,
    isStatePortal,
    isCountyUser,
    isLocalUnitUser,
    handleReturnToLocalUnit,
    renderCount,
    handleSubmitToCounty,
    isStateViewLocalUnit4022
  ]);

  const formPdfs = useMemo(() => {
    const pdfs = localUnitForm?.formPdfs ?? [];
    if (isStatePortal && !isStateViewLocalUnit4022) {
      return pdfs.filter(({localUnit}) => !localUnit);
    } else if (isStatePortal && isStateViewLocalUnit4022) {
      return pdfs.filter(({localUnit}) => localUnit);
    } else {
      return pdfs;
    }
  }, [
    isStatePortal,
    localUnitForm,
    isStateViewLocalUnit4022
  ]);

  return (
    <Container fluid className="LocalUnitForm">
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && !loadingState.loadError &&
        <>
          {(shouldShowLock || shouldShowLockSwitch) && <BreadcrumbsNav breadcrumbs={breadcrumbs}/>}
          <Row className="mb-3">
            {!shouldShowLock && !shouldShowLockSwitch && <Col md="6">
              <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
            </Col>}
            {(shouldShowLock || shouldShowLockSwitch) &&
              <Col className="pt-2 d-flex align-items-center" md="2">
                {shouldShowLockSwitch && <>
                  <CustomInput type="switch"
                               id="lockFormSwitch"
                               name="lockForm"
                               label="Locked"
                               onChange={() => setLockModalIsOpen(true)}
                               checked={isLocked}
                               disabled={loadingState.loading || loadingState.processing}/>
                </>}
                {shouldShowLock && <>
                  <FontAwesomeIcon className="text-danger ml-2 mr-2"
                                   icon="lock"
                                   title="Locked"
                                   aria-label="Locked"/>
                  <strong>Locked</strong>
                </>}
              </Col>}

            <Col md={!shouldShowLock && !shouldShowLockSwitch ? 6 : 10} className="d-flex justify-content-end">
              {shouldShowImportButton && <Button color="primary"
                                                 disabled={loadingState.processing || isLocked}
                                                 onClick={() => setImportModalIsOpen(true)}>
                Unit Import
              </Button>}
              <Button color="primary"
                      className="ml-2"
                      disabled={loadingState.loading || formPdfs.length === 0}
                      onClick={handleViewPdfsButtonClick}>
                View PDFs
              </Button>
              {shouldShowResetButton && <Button color="danger"
                                                className="ml-2"
                                                onClick={() => toggleResetModal()}
                                                disabled={isResetDisabled}>
                Reset Form
              </Button>}
            </Col>
          </Row>
          {renderFormComponent()}
        </>
      }

      {localUnitForm && <>
        <ResetModal isOpen={resetModalIsOpen}
                    onConfirm={handleReset}
                    onCancel={toggleResetModal}
                    formName={scrubbedFormName ?? ''}
                    formDescription={localUnitForm.description}
                    localityDisplayName={localUnitForm.localUnitDisplayName ?? ''}/>

        <LockModal isOpen={lockModalIsOpen}
                   locked={localUnitForm.locked}
                   formName={scrubbedFormName ?? ''}
                   formDescription={localUnitForm.description}
                   localityDisplayName={localUnitForm.localUnitDisplayName ?? ''}
                   onConfirm={() => handleLockSave(!localUnitForm.locked)}
                   onCancel={() => setLockModalIsOpen(false)}/>
      </>}

      <JsonImportModal isOpen={importModalIsOpen}
                       errorMessages={importErrorMessages}
                       onMappingKeyToggle={handleJsonImportMappingKeyToggle}
                       onSubmit={handleImport}
                       importWillOverwriteData={willImportOverwriteData}
                       onToggle={handleImportModalToggle}
                       form={localUnitForm as LocalUnitFormDto}/>

      <ProgressModal isOpen={loadingState.saving && shouldShowProgressModal}
                     title={progressModalActionText.header}
                     content={`Form is being ${progressModalActionText.action}. Please do not refresh the page, as this could take a few moments.`}/>
    </Container>
  );
};

export default LocalUnitForm;