import {memo, useMemo} from 'react';

import {CustomTable} from '@reasoncorp/kyber-js';

import * as messages from '../../../messages';
import {forms, StudyPeriod} from '../../../types';
import {formatDate} from '../../../utils';
import Form4015PropertyRow from './Form4015PropertyRow';

type Props = {
  studyPeriod: StudyPeriod
  properties: forms.Form4015PropertyDto[]
  showReadOnlyView: boolean
  onEdit: (property: forms.Form4015PropertyDto) => void
  onDelete: (property: forms.Form4015PropertyDto) => void
  onContend: (property: forms.Form4015PropertyDto) => void
  onContendAndCommentToggle: (property: forms.Form4015PropertyDto, comment?: string) => void
  isLocalUnitUser: boolean
  isContendDisabled: boolean
  isCountyUser: boolean
}

const Form4015Period = ({
                          studyPeriod,
                          properties,
                          showReadOnlyView,
                          isContendDisabled,
                          onEdit,
                          onDelete,
                          onContend,
                          onContendAndCommentToggle,
                          isLocalUnitUser,
                          isCountyUser
                        }: Props) => {
  const headerColSpan = useMemo(() => {
    if (showReadOnlyView) {
      return 17;
    } else {
      return 20;
    }
  }, [
    showReadOnlyView
  ]);

  const renderRow = useMemo(() => (property: forms.Form4015PropertyDto) => {
    return <Form4015PropertyRow property={property}
                                showReadOnlyView={showReadOnlyView}
                                isContendDisabled={isContendDisabled}
                                key={property.id}
                                isLocalUnitUser={isLocalUnitUser}
                                isCountyUser={isCountyUser}
                                onEdit={onEdit}
                                onDelete={() => onDelete(property)}
                                onContend={onContend}
                                onContendAndCommentToggle={onContendAndCommentToggle}
    />;
  }, [
    isContendDisabled,
    showReadOnlyView,
    onEdit,
    onDelete,
    onContend,
    isLocalUnitUser,
    isCountyUser,
    onContendAndCommentToggle
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      [{
        title: formatDate(studyPeriod.begin) + '-' + formatDate(studyPeriod.end),
        colSpan: headerColSpan
      }],
      [{
        title: 'Confidential',
        className: 'text-nowrap',
        sortKey: 'confidential'
      }, {
        title: 'Twice Sold',
        className: 'text-nowrap text-center',
        sortKey: 'isDuplicate'
      }, {
        title: 'LU',
        className: 'text-nowrap text-center',
        sortKey: 'wasAddedByLocalUnit'
      }, {
        title: 'Date',
        className: 'text-nowrap text-center',
        sortKey: 'date'
      }, {
        title: 'Liber & Page',
        className: 'text-nowrap text-center',
        sortKey: 'liberAndPage'
      }, {
        title: 'Parcel Number',
        className: 'text-nowrap text-center',
        sortKey: 'propertyNumber'
      }, {
        title: 'Grantor',
        className: 'text-nowrap text-center',
        sortKey: 'grantor'
      }, {
        title: 'Grantee',
        className: 'text-nowrap text-center',
        sortKey: 'grantee'
      }, {
        title: 'Instrument',
        className: 'text-nowrap text-center',
        sortKey: 'instrument'
      }, {
        title: 'Financing',
        className: 'text-nowrap text-center',
        sortKey: 'financing'
      }, {
        title: 'Assessed Value',
        className: 'text-nowrap text-center',
        sortKey: 'assessedValue'
      }, {
        title: 'Sales Price',
        className: 'text-nowrap text-center',
        sortKey: 'salesPrice'
      }, {
        title: 'Adjusted Sales Price',
        className: 'text-nowrap text-center',
        sortKey: 'adjustedSalesPrice'
      }, {
        title: 'Ratio',
        className: 'text-nowrap text-center',
        sortKey: 'ratio'
      }, {
        title: 'Comments',
        className: 'text-nowrap'
      }, {
        title: 'Contend',
        className: 'text-nowrap text-center'
      }, {
        title: 'Edit Parcel',
        className: 'text-nowrap text-center',
        hide: showReadOnlyView
      },
        {
          title: 'Delete Parcel',
          className: 'text-nowrap text-center',
          hide: showReadOnlyView
        }
      ]
    ],
    noResultsMessage: messages.NO_PARCELS_ADDED,
    items: properties,
    renderRow,
    initialSort: [
      {
        sortKey: 'isDuplicate',
        direction: 'desc' as const
      }, {
        sortKey: 'date',
        direction: 'desc' as const
      }],
    chainSort: true,
    paginatorConfig: {
      perPage: 100,
      recordName: 'parcels',
      allowShowAll: true
    }
  }), [
    properties,
    showReadOnlyView,
    renderRow,
    studyPeriod,
    headerColSpan
  ]);

  return <div className="mb-4">
    <CustomTable {...tableProps} />
  </div>;
};

export default memo(Form4015Period);