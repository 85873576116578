import {useCallback, useMemo} from 'react';

import {Col, Pagination, PaginationItem, PaginationLink, Row} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';

import {LocalUnitFormDto, OtherFormInCounty} from '../../types';
import {FormStatus} from '../../enum';

type Props = {
  localUnitForm: LocalUnitFormDto,
  isStateUser: boolean
}

const LocalUnitFormNavigator = ({
                                  localUnitForm,
                                  isStateUser
                                }: Props) => {
  const navigate = useNavigate();
  const {year, countyId, countyFormId} = useParams<{
    year: string,
    countyId: string,
    countyFormId: string
  }>();

  const currentIndex = useMemo(() => {
    return localUnitForm.otherFormsInCounty.indexOf(
      localUnitForm.otherFormsInCounty
        .filter((otherFormInCounty: OtherFormInCounty) => otherFormInCounty.localUnitId === localUnitForm.localUnitId)[0]
    );
  }, [
    localUnitForm
  ]);

  const portal = useMemo(() => {
    return isStateUser ? '/state-portal/' : '/county-portal/';
  }, [
    isStateUser
  ]);

  const formPathForIndex = useCallback((targetForm: OtherFormInCounty) => {
    const localUnitId = targetForm.localUnitId;
    const localUnitFormId = targetForm.localUnitFormId;
    return `${portal}${countyId}/${year}/forms/${countyFormId}/local-units/${localUnitId}/forms/${localUnitFormId}`;
  }, [
    portal,
    countyFormId,
    year,
    countyId
  ]);

  const nextLocalUnit = useMemo(() => {
    if (currentIndex === localUnitForm.otherFormsInCounty.length - 1) {
      return undefined;
    } else if (isStateUser) {
      return localUnitForm.otherFormsInCounty
        .slice(currentIndex + 1)
        .find((otherFormInCounty: OtherFormInCounty) => otherFormInCounty.localUnitStateStatus !== FormStatus.NOT_STARTED);
    } else {
      return localUnitForm.otherFormsInCounty[currentIndex + 1];
    }
  }, [
    currentIndex,
    isStateUser,
    localUnitForm
  ]);

  const previousLocalUnit = useMemo(() => {
    if (currentIndex === 0) {
      return undefined;
    } else if (isStateUser) {
      return localUnitForm.otherFormsInCounty
        .slice(0, currentIndex)
        .reverse()
        .find((otherFormInCounty: OtherFormInCounty) => otherFormInCounty.localUnitStateStatus !== FormStatus.NOT_STARTED);
    } else {
      return localUnitForm.otherFormsInCounty[currentIndex - 1];
    }
  }, [
    currentIndex,
    isStateUser,
    localUnitForm
  ]);

  const navigateToForm = useCallback((targetForm: OtherFormInCounty | undefined) => {
    if (targetForm !== undefined) {
      navigate(formPathForIndex(targetForm));
    }
  }, [
    formPathForIndex,
    navigate
  ]);

  return <Row className="mt-3">
    <Col className="d-flex justify-content-end Paginator">
      <Pagination>
        <PaginationItem disabled={previousLocalUnit === undefined}>
          <PaginationLink previous
                          aria-label="Previous Unit"
                          onClick={() => navigateToForm(previousLocalUnit)}>
            &lt; Previous Unit
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={nextLocalUnit === undefined}>
          <PaginationLink next
                          aria-label="Next Unit"
                          onClick={() => navigateToForm(nextLocalUnit)}>
            Next Unit &gt;
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </Col>
  </Row>;
};

export default LocalUnitFormNavigator;