import {useMemo} from 'react';
import {Button, Col, Row} from 'reactstrap';

import {useUserContext} from '@reasoncorp/kyber-js';

type Props = {
  showSubmitButton?: boolean
  onSave: () => void
  saveDisabled: boolean
  submitDisabled: boolean
  submitButtonText: string
  onToggleCommentModal: () => void
  onToggleResubmitModal: () => void
  onToggleSubmitModal: () => void
  showSendToLocalUnitButton?: boolean
  isSendToReviewDisabled?: boolean
  onSendToReview?: () => void
}

const FormActionButtons = ({
                             showSubmitButton = true,
                             onSave,
                             saveDisabled,
                             submitDisabled,
                             submitButtonText,
                             onToggleCommentModal,
                             onToggleResubmitModal,
                             onToggleSubmitModal,
                             showSendToLocalUnitButton = false,
                             isSendToReviewDisabled = false,
                             onSendToReview = () => {
                             }
                           }: Props) => {
  const {permissions} = useUserContext();

  const handleSubmit = useMemo(() => {
    if (submitButtonText === 'Amend') {
      return onToggleCommentModal;
    } else if (submitButtonText === 'Resubmit') {
      return onToggleResubmitModal;
    } else {
      return onToggleSubmitModal;
    }
  }, [
    onToggleSubmitModal,
    onToggleResubmitModal,
    onToggleCommentModal,
    submitButtonText
  ]);

  const color = useMemo(() => {
    return submitButtonText === 'Amend' ? 'danger' : 'primary';
  }, [
    submitButtonText
  ]);

  return <Row>
    <Col className="d-flex justify-content-end">
      <Button color="success"
              onClick={() => onSave()}
              disabled={saveDisabled}>
        Save
      </Button>
      {showSendToLocalUnitButton && permissions.hasCountySubmitAccess && <Button className="ml-2"
                                                                                 color="primary"
                                                                                 disabled={isSendToReviewDisabled}
                                                                                 onClick={() => onSendToReview()}>
        Send to Local Unit
      </Button>}
      {showSubmitButton && <Button color={color}
                                   onClick={handleSubmit}
                                   className="ml-2"
                                   disabled={submitDisabled}>
        {submitButtonText}
      </Button>}
    </Col>
  </Row>;
};

export default FormActionButtons;