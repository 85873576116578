import {useCallback, useMemo, useState} from 'react';

import {Formik, FormikProps} from 'formik';
import {Card, CardBody, CardHeader} from 'reactstrap';

import {TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  StateFormButtons,
  StateFormModals,
  UnsavedChangesWarningModal
} from '../../components/shared';
import {form4024Schema} from '../../schemas';
import {AmendmentRequest, CountyFormDto, forms, ReturnRequest} from '../../types';
import {
  Form4024RealAssessedValuationsTab,
  Form4024RealEqualizedValuationsTab,
  Form4024TotalsTab
} from '../../components/forms/form4024';
import * as form4024Utils from '../../utils/form4024Utils';
import {useUnsavedChangesWarning} from '../../hooks';

const tabs = [
  {value: 'totals', displayValue: 'Real and Personal Totals'},
  {value: 'realEqualizedValuations', displayValue: 'Real Equalized Valuations'},
  {value: 'realAssessedValuations', displayValue: 'Real Assessed Valuations'}
];

type Props = {
  form: CountyFormDto
  loading: boolean
  onSave: (countyFormData: forms.CountyFormData, afterSave?: () => void) => Promise<void>
  onSubmit: (countyFormData: forms.CountyFormData) => void
  submitButtonText: string
  onResubmit: (countyFormData: forms.CountyFormData) => void
  onAmend: (amendmentRequest: AmendmentRequest, countyFormData: forms.CountyFormData) => void
  isStateUser: boolean
  onAccept: () => void
  onReturn: (returnRequest: ReturnRequest) => void
}

const Form4024 = ({
                    form,
                    loading,
                    onSave,
                    onSubmit,
                    submitButtonText,
                    onResubmit,
                    onAmend,
                    isStateUser,
                    onAccept,
                    onReturn
                  }: Props) => {
  const {selectedTab} = useTabNav();
  const {setHasUnsavedChanges} = useUnsavedChangesWarning();
  const [submitModalIsOpen, setSubmitModelIsOpen] = useState(false);
  const [resubmitModalIsOpen, setResubmitModelIsOpen] = useState(false);
  const [commentModalIsOpen, setCommentModelIsOpen] = useState(false);
  const [acceptModalIsOpen, setAcceptModelIsOpen] = useState(false);
  // Use latest form submission data if available for state users
  const form4024Dto = useMemo(() => {
    if (isStateUser && form.latestSubmissionData !== null) {
      return form.latestSubmissionData as forms.Form4024Dto;
    } else {
      return form.data as forms.Form4024Dto;
    }
  }, [isStateUser, form]);

  const getSavableData = useCallback((formikProps: FormikProps<any>) => {
    return {
      type: 'FORM_4024' as const,
      localUnits: formikProps.values.localUnits
    };
  }, []);

  const toggleAcceptModal = useCallback((confirmAccept = false) => {
    if (confirmAccept) {
      onAccept();
    }
    setAcceptModelIsOpen(!acceptModalIsOpen);
  }, [onAccept, acceptModalIsOpen]);

  const toggleCommentModal = useCallback(() => setCommentModelIsOpen(!commentModalIsOpen), [commentModalIsOpen]);

  const toggleSubmitModal = useCallback((confirmSubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmSubmit && formikProps) {
      onSubmit(getSavableData(formikProps));
    }

    setSubmitModelIsOpen(!submitModalIsOpen);
  }, [onSubmit, getSavableData, submitModalIsOpen]);

  const toggleResubmitModal = useCallback((confirmResubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmResubmit && formikProps) {
      onResubmit(getSavableData(formikProps));
    }
    setResubmitModelIsOpen(!resubmitModalIsOpen);
  }, [onResubmit, getSavableData, resubmitModalIsOpen]);

  const handleAmend = useCallback((amendmentRequest: AmendmentRequest, formikProps: FormikProps<any>) =>
      onAmend(amendmentRequest, getSavableData(formikProps)),
    [onAmend, getSavableData]
  );

  const handleSave = useCallback((formikProps: FormikProps<any>,
                                  afterSave?: () => void) => {
    return onSave(getSavableData(formikProps), afterSave);
  }, [
    onSave,
    getSavableData
  ]);

  const shouldDisplayStateFormButtons = useMemo(() => isStateUser && form.status !== 'IN_PROGRESS',
    [isStateUser, form.status]
  );

  const showReadOnlyView = useMemo(() => isStateUser || form.locked, [isStateUser, form.locked]);

  return <div className="Form4024">
    <Formik initialValues={form4024Utils.getInitialValues(form4024Dto, form.localUnits)}
            validationSchema={form4024Schema}
            enableReinitialize={true}
            onSubmit={async () => null}
            validateOnMount={true}>
      {(formikProps) => {
        const totals = form4024Utils.calculateTotals(formikProps);
        const localUnits = (formikProps.values.localUnits as forms.Form4024LocalUnitDto[]);

        return <>
          <Card className="mb-3">
            <FormHeader form={form}/>
            <CardHeader className="nav-tabs-header">
              <TabNav/>
            </CardHeader>
            <CardBody>
              {selectedTab === 'totals' && <Form4024TotalsTab totals={totals}
                                                              localUnits={localUnits}
                                                              showReadOnlyView={showReadOnlyView}
                                                              setHasUnsavedChanges={setHasUnsavedChanges}/>}
              {selectedTab === 'realEqualizedValuations' &&
                <Form4024RealEqualizedValuationsTab totals={totals}
                                                    localUnits={localUnits}
                                                    showReadOnlyView={showReadOnlyView}
                                                    setHasUnsavedChanges={setHasUnsavedChanges}/>}
              {selectedTab === 'realAssessedValuations' &&
                <Form4024RealAssessedValuationsTab totals={totals}
                                                   localUnits={localUnits}
                                                   showReadOnlyView={showReadOnlyView}
                                                   setHasUnsavedChanges={setHasUnsavedChanges}/>}
            </CardBody>
          </Card>

          <FormHistoryTable items={form.formHistory}/>

          {!isStateUser && <>
            <UnsavedChangesWarningModal onSave={(afterSave) => handleSave(formikProps, afterSave)}/>

            <FormModals form={form}
                        resubmitFormModalIsOpen={resubmitModalIsOpen}
                        submitFormModalIsOpen={submitModalIsOpen}
                        amendModalIsOpen={commentModalIsOpen}
                        onSubmit={() => toggleSubmitModal(true, formikProps)}
                        onCancelSubmit={() => toggleSubmitModal(false)}
                        onResubmit={() => toggleResubmitModal(true, formikProps)}
                        onResubmitCancel={() => toggleResubmitModal(false)}
                        onAmend={(amendmentRequest: AmendmentRequest) => handleAmend(amendmentRequest, formikProps)}
                        toggleAmend={() => toggleCommentModal()}/>
            <FormActionButtons saveDisabled={loading || form.locked}
                               submitDisabled={loading || !formikProps.isValid || formikProps.isSubmitting || form.locked}
                               submitButtonText={submitButtonText}
                               onSave={() => handleSave(formikProps)}
                               onToggleCommentModal={() => toggleCommentModal()}
                               onToggleResubmitModal={() => toggleResubmitModal(false, formikProps)}
                               onToggleSubmitModal={() => toggleSubmitModal(false, formikProps)}/>
          </>}

          {shouldDisplayStateFormButtons && <>
            <StateFormButtons loading={loading}
                              onReturnClick={() => toggleCommentModal()}
                              onAcceptClick={() => toggleAcceptModal(false)}/>
            <StateFormModals onReturn={onReturn}
                             returnModalIsOpen={commentModalIsOpen}
                             onReturnCancel={() => toggleCommentModal()}
                             onAccept={() => toggleAcceptModal(true)}
                             acceptModalIsOpen={acceptModalIsOpen}
                             onAcceptCancel={() => toggleAcceptModal()}
                             form={form}/>
          </>}
        </>;
      }}
    </Formik>
  </div>;
};

export default withTabNav(Form4024, {tabs});