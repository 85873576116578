import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';
import {Formik, FormikHelpers} from 'formik';

import {
  BreadcrumbsNav,
  ConfirmationModal,
  FormikCheckboxGroup,
  FormikInput,
  ProgressIndicator,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {megApi, studyCertificationApi} from '../api';
import * as messages from '../messages';
import {County, StudyCertificationQuestions, StudyCertificationType} from '../types';
import {studyCertificationSchema} from '../schemas';
import {formatDate} from '../utils';
import {usePortal, useUnsavedChangesWarning} from '../hooks';

const StudyCertification = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const {permissions} = useUserContext();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [county, setCounty] = useState<County | undefined>(undefined);
  const [studyCertification, setStudyCertification] = useState<StudyCertificationType | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {isStatePortal} = usePortal();
  const {setHasUnsavedChanges, hasUnsavedChanges} = useUnsavedChangesWarning();

  useEffect(() => {
    const findStudyCertification = async () => {
      try {
        const [counties, studyCertification] = await Promise.all([
          megApi.findCounties(isStatePortal),
          studyCertificationApi.findByCountyIdAndYear(countyId, year)
        ]);
        const currentCounty = counties.filter(county => county.id === Number(countyId))[0];
        setCounty(currentCounty);
        setStudyCertification(studyCertification);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void findStudyCertification();
  }, [
    countyId,
    year,
    showErrorAlert,
    permissions,
    isStatePortal
  ]);

  const breadcrumbs = useMemo(() => {
    if (isStatePortal) {
      return [
        {text: 'State Dashboard', active: false, route: `/state-portal`, icon: 'home' as const},
        {text: county?.displayName ?? '', active: true}
      ];
    } else {
      return [
        {text: 'County Dashboard', active: false, route: `/county-portal/${countyId}/${year}`, icon: 'home' as const},
        {text: county?.displayName ?? '', active: true}
      ];
    }
  }, [
    isStatePortal,
    county,
    countyId,
    year
  ]);

  const initialValues: StudyCertificationQuestions = useMemo(() => ({
    hasEcfAnalysisBeenUploaded: studyCertification?.studyCertificationQuestions.hasEcfAnalysisBeenUploaded || false,
    hasLandValueAnalysisBeenUploaded: studyCertification?.studyCertificationQuestions.hasLandValueAnalysisBeenUploaded || false,
    hasForm3215BeenUploaded: studyCertification?.studyCertificationQuestions.hasForm3215BeenUploaded || false,
    pin: ''
  }), [
    studyCertification
  ]);

  const handleSubmit = useCallback(async (studyCertificationQuestions: StudyCertificationQuestions,
                                          actions: FormikHelpers<StudyCertificationQuestions>) => {
    setLoadingState({...loadingState, processing: true});

    try {
      const studyCertification = await studyCertificationApi.submit(countyId, year, studyCertificationQuestions);
      setStudyCertification(studyCertification);
      showSuccessAlert(messages.STUDY_CERTIFICATION_SUBMIT_SUCCESSFUL);
    } catch (e) {
      const statusCode = (e as {status: number})?.status ?? 500;
      if (statusCode === 422) {
        showErrorAlert(messages.STUDY_CERTIFICATION_PIN_INVALID);
      } else {
        showErrorAlert(messages.STUDY_CERTIFICATION_SUBMIT_FAILURE);
      }
    }

    setLoadingState({...loadingState, processing: false});
    actions.setSubmitting(false);
    setIsModalOpen(false);
  }, [
    countyId,
    loadingState,
    showSuccessAlert,
    showErrorAlert,
    year
  ]);

  const shouldDisplayForm3215BeenUploadedRow = useMemo(() => Number(year) < 2025, [year]);

  const shouldDisplayPinRow = useMemo(() => {
    return !isStatePortal && Number(year) >= 2025 && !studyCertification?.submitted;
  }, [
    isStatePortal,
    studyCertification?.submitted,
    year
  ]);

  return <Container fluid className="StudyCertification">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && <>
      <Formik initialValues={initialValues}
              validationSchema={studyCertificationSchema(Number(year))}
              enableReinitialize={true}
              validateOnMount={true}
              onSubmit={handleSubmit}>
        {(formikProps) => (<>
          <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
          <Row className="mt-2">
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      Certification
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      I certify that the County Equalization Study for the county indicated above was prepared under my direct supervision in my role as Equalization Director. In compliance with Michigan Compiled Laws 211.148, and the rules of the State Tax Commission, I am certified as an assessor at the level required for this county.
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <FormikCheckboxGroup checkboxes={[{
                        onChange: () => setHasUnsavedChanges(true),
                        required: true,
                        disabled: studyCertification?.submitted,
                        name: 'hasLandValueAnalysisBeenUploaded',
                        labelText: 'Land Value Analysis has been uploaded to filing cabinet'
                      }]}/>
                    </Col>
                  </Row>
                  <Row className={shouldDisplayPinRow ? 'mb-3' : ''}>
                    <Col>
                      <FormikCheckboxGroup checkboxes={[{
                        onChange: () => setHasUnsavedChanges(true),
                        required: true,
                        disabled: studyCertification?.submitted,
                        name: 'hasEcfAnalysisBeenUploaded',
                        labelText: 'ECF Analysis has been uploaded to filing cabinet'
                      }]}/>
                    </Col>
                  </Row>
                  {shouldDisplayForm3215BeenUploadedRow && <Row>
                    <Col>
                      <FormikCheckboxGroup checkboxes={[{
                        onChange: () => setHasUnsavedChanges(true),
                        disabled: studyCertification?.submitted,
                        required: true,
                        name: 'hasForm3215BeenUploaded',
                        labelText: 'Form 3215 has been signed and uploaded to filing cabinet'
                      }]}/>
                    </Col>
                  </Row>}
                  {shouldDisplayPinRow && <Row style={{borderTop: '1px solid #DDD'}} className="pt-4">
                    <Col>
                      <strong className="mb-2">
                        Enter the secure PIN number provided by Treasury for {county?.displayName}
                      </strong>
                      <FormikInput name="pin"
                                   onChange={() => setHasUnsavedChanges(true)}
                                   required
                                   labelText="Verify Pin"
                                   maxLength={6}/>
                    </Col>
                  </Row>}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="">
            {!isStatePortal &&
              <Col className="mt-4 col-12 d-flex justify-content-end">
                <Button color="primary"
                        disabled={!formikProps.isValid || studyCertification?.submitted || !hasUnsavedChanges}
                        onClick={() => setIsModalOpen(true)}>
                  Submit
                </Button>
              </Col>}
            {studyCertification && studyCertification.submitted &&
              <Col className="col-12 mt-3 d-flex justify-content-end font-weight-bold">
                Submitted {formatDate(studyCertification.submittedAt)}
              </Col>}
          </Row>
          <ConfirmationModal isOpen={isModalOpen}
                             confirmCallback={formikProps.submitForm}
                             cancelCallback={() => setIsModalOpen(false)}
                             size="lg"
                             confirmButtonText="Yes"
                             cancelButtonText="Cancel"
                             title="Submit Study Certification">
            Are you sure you want to submit the Study Certification for <span className="text-danger">{county?.displayName ?? ''}</span>?
          </ConfirmationModal>
        </>)}
      </Formik>
    </>}
  </Container>;
};

export default StudyCertification;