import {useCallback, useMemo, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import {Card, CardBody, CardHeader} from 'reactstrap';

import {TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  LocalUnitFormNavigator,
  StateFormButtons,
  StateFormModals,
  UnsavedChangesWarningModal
} from '../../../components/shared';
import {form4018V2Schema} from '../../../schemas';
import {AmendmentRequest, forms, LocalUnitFormDto, ReturnRequest} from '../../../types';
import * as form4018V2Utils from '../../../utils/form4018V2Utils';
import {Form4018V2PersonalPropertyTab, Form4018V2RealPropertyTab} from '../../../components/forms/form4018V2';
import {useUnsavedChangesWarning} from '../../../hooks';

const tabs = [
  {value: 'realProperty', displayValue: 'Real Property'},
  {value: 'personalProperty', displayValue: 'Personal Property'}
];

type Props = {
  loading: boolean
  form: LocalUnitFormDto
  isStateUser: boolean
  submitButtonText: string
  isLocalUnitUser: boolean
  onSave: (localUnitFormData: forms.LocalUnitFormData, afterSave?: () => void) => Promise<void>
  onSubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.LocalUnitFormData) => void
  onResubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onAccept: () => void
  onReturn: (returnRequest: ReturnRequest) => void
}

const Form4018V2 = ({
                      loading,
                      form,
                      isStateUser,
                      submitButtonText,
                      isLocalUnitUser,
                      onSave,
                      onSubmit,
                      onAmend,
                      onResubmit,
                      onAccept,
                      onReturn
                    }: Props) => {
  const {selectedTab} = useTabNav();
  const {setHasUnsavedChanges} = useUnsavedChangesWarning();
  const [commentModalIsOpen, toggleCommentModal] = useState(false);
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [resubmitModalIsOpen, setReSubmitModalIsOpen] = useState(false);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);

  const form4018V2Dto = useMemo(() => (
    // Use latest form submission data if available for state users
    isStateUser && form.latestSubmissionData !== null ? form.latestSubmissionData : form.data
  ) as forms.Form4018V2Dto, [form, isStateUser]);

  const shouldDisplayFormActionButtons = useMemo(() => {
    return !isStateUser && !isLocalUnitUser;
  }, [
    isStateUser,
    isLocalUnitUser
  ]);

  const shouldDisplayStateFormButtons = useMemo(() => {
    return isStateUser && form.status !== 'IN_PROGRESS';
  }, [
    isStateUser,
    form.status
  ]);

  const showReadOnlyView = useMemo(() => {
    return isStateUser || isLocalUnitUser || form.locked;
  }, [
    isStateUser,
    isLocalUnitUser,
    form.locked
  ]);

  const getSaveableData = useCallback((formikProps: FormikProps<any>) => {
    return {
      type: 'FORM_4018_V2' as const,
      realProperty: formikProps.values.realProperty,
      personalProperty: formikProps.values.personalProperty,
      version: 'V2'
    };
  }, []);

  const handleSave = useCallback((formikProps: FormikProps<any>,
                                  afterSave?: () => void) => {
    return onSave(getSaveableData(formikProps), afterSave);
  }, [
    getSaveableData,
    onSave
  ]);

  const handleAmend = useCallback((formikProps: FormikProps<any>,
                                   amendmentRequest: AmendmentRequest) =>
    onAmend(amendmentRequest, getSaveableData(formikProps)), [getSaveableData, onAmend]);

  const handleSubmit = useCallback((formikProps: FormikProps<any>) =>
    onSubmit(getSaveableData(formikProps)), [getSaveableData, onSubmit]);

  const toggleSubmitModal = useCallback((confirmSubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmSubmit && formikProps) {
      handleSubmit(formikProps);
    }
    setSubmitModalIsOpen(!submitModalIsOpen);
  }, [handleSubmit, submitModalIsOpen]);

  const handleResubmit = useCallback((formikProps: FormikProps<any>) =>
    onResubmit(getSaveableData(formikProps)), [getSaveableData, onResubmit]);

  const toggleResubmitModal = useCallback((confirmResubmit = false,
                                           formikProps?: FormikProps<any>) => {
    if (confirmResubmit && formikProps) {
      handleResubmit(formikProps);
    }
    setReSubmitModalIsOpen(!resubmitModalIsOpen);
  }, [handleResubmit, resubmitModalIsOpen]);

  const toggleAcceptModal = useCallback((confirmAccept = false) => {
    if (confirmAccept) {
      onAccept();
    }
    setAcceptModalIsOpen(!acceptModalIsOpen);
  }, [acceptModalIsOpen, onAccept]);

  return <div className="Form4018V2">
    <Formik initialValues={form4018V2Utils.getInitialValues(form4018V2Dto)}
            validationSchema={form4018V2Schema}
            enableReinitialize={true}
            onSubmit={async () => null}
            validateOnMount={true}>
      {(formikProps) => {
        return <>
          <Card className="mb-3">
            <FormHeader form={form}/>
            <CardHeader className="nav-tabs-header">
              <TabNav/>
            </CardHeader>
            <CardBody>
              {selectedTab === 'realProperty' &&
                <Form4018V2RealPropertyTab form4018V2Dto={formikProps.values as forms.Form4018V2Dto}
                                           showReadOnlyView={showReadOnlyView}
                                           setHasUnsavedChanges={setHasUnsavedChanges}/>}
              {selectedTab === 'personalProperty' &&
                <Form4018V2PersonalPropertyTab form4018V2Dto={formikProps.values as forms.Form4018V2Dto}
                                               showReadOnlyView={showReadOnlyView}
                                               setHasUnsavedChanges={setHasUnsavedChanges}/>}
            </CardBody>
          </Card>

          <FormHistoryTable items={form.formHistory}/>

          {shouldDisplayFormActionButtons && <>
            <UnsavedChangesWarningModal onSave={(afterSave) => handleSave(formikProps, afterSave)}/>

            <FormActionButtons submitDisabled={loading || !formikProps.isValid || formikProps.isSubmitting || form.locked}
                               saveDisabled={loading || form.locked}
                               submitButtonText={submitButtonText}
                               onSave={() => handleSave(formikProps)}
                               onToggleCommentModal={() => toggleCommentModal(true)}
                               onToggleResubmitModal={() => toggleResubmitModal(false, formikProps)}
                               onToggleSubmitModal={() => toggleSubmitModal(false, formikProps)}/>

            <FormModals form={form}
                        resubmitFormModalIsOpen={resubmitModalIsOpen}
                        submitFormModalIsOpen={submitModalIsOpen}
                        amendModalIsOpen={commentModalIsOpen}
                        onSubmit={() => toggleSubmitModal(true, formikProps)}
                        onCancelSubmit={() => toggleSubmitModal(false)}
                        onResubmit={() => toggleResubmitModal(true, formikProps)}
                        onResubmitCancel={() => toggleResubmitModal(false)}
                        onAmend={(values) => handleAmend(formikProps, values)}
                        toggleAmend={() => toggleCommentModal(false)}/>
          </>}

          {shouldDisplayStateFormButtons && <>
            <StateFormModals onReturn={onReturn}
                             returnModalIsOpen={commentModalIsOpen}
                             onReturnCancel={() => toggleCommentModal(false)}
                             onAccept={() => toggleAcceptModal(true)}
                             acceptModalIsOpen={acceptModalIsOpen}
                             onAcceptCancel={() => toggleAcceptModal()}
                             form={form}/>
            <StateFormButtons loading={loading}
                              onAcceptClick={() => toggleAcceptModal()}
                              onReturnClick={() => toggleCommentModal(true)}/>
          </>}

          {!isLocalUnitUser && <LocalUnitFormNavigator localUnitForm={form}
                                                       isStateUser={isStateUser}/>}
        </>;
      }}
    </Formik>
  </div>;
};

export default withTabNav(Form4018V2, {tabs});