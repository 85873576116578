import {JsonImportResult} from '../types';
import {megApi} from './apiUtils';

export const importCountyForm = (countyId: number | string,
                                 countyFormId: number | string,
                                 file: FormData
): Promise<JsonImportResult> => megApi.postWithJsonResponse(
  `/counties/${countyId}/forms/${countyFormId}/import`,
  {body: file}
);

export const importLocalUnitForm = (localUnitId: number | string,
                                    localUnitFormId: number | string,
                                    file: FormData,
                                    isLocalUnit: boolean): Promise<JsonImportResult> => megApi.postWithJsonResponse(
  `/local-units/${localUnitId}/forms/${localUnitFormId}/import?isLocalUnit=${isLocalUnit}`,
  {body: file}
);
