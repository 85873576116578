import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, CustomInput, Row, Table} from 'reactstrap';

import {ConfirmationModal, CustomTable, TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {Form4014aPropertyModal, Form4014aPropertyRow} from '../../components/forms/form4014a';
import {
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  LocalUnitFormNavigator,
  NoPropertiesModal,
  StateFormButtons,
  StateFormModals,
  UnsavedChangesWarningModal
} from '../../components/shared';
import {filterPropertiesByClassification, formatInteger, PROPERTY_CLASSIFICATION_TABS} from '../../utils';
import * as messages from '../../messages';
import {AmendmentRequest, forms, LocalUnitFormDto, ReturnRequest} from '../../types';
import {useUnsavedChangesWarning} from '../../hooks';

type Props = {
  form: LocalUnitFormDto
  loading: boolean
  isStateUser: boolean
  submitButtonText: string
  onReturn: (returnRequest: ReturnRequest) => void
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.LocalUnitFormData) => void
  onSubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onResubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onSave: (localUnitFormData: forms.LocalUnitFormData, afterSave?: () => void) => Promise<void>,
  onAccept: () => void,
}

const Form4014a = ({
                     form,
                     loading,
                     isStateUser,
                     submitButtonText,
                     onReturn,
                     onAmend,
                     onSubmit,
                     onResubmit,
                     onSave,
                     onAccept
                   }: Props) => {
    const {setHasUnsavedChanges} = useUnsavedChangesWarning();

    const form4014aDto = useMemo(() => {
      if (isStateUser && form.latestSubmissionData) {
        // Use latest form submission data if available for state users
        return form.latestSubmissionData as forms.Form4014aDto;
      } else {
        return form.data as forms.Form4014aDto;
      }
    }, [
      form,
      isStateUser
    ]);

    const [noPropertiesModalIsOpen, setNoPropertiesModalIsOpen] = useState(false);
    const [propertyModalIsOpen, setPropertyModalIsOpen] = useState(false);
    const [propertyModalOperation, setPropertyModalOperation] = useState('Add');
    const [selectedProperty, setSelectedProperty] = useState<forms.Form4014aPropertyDto | undefined>(undefined);
    const [noPropertiesSelections, setNoPropertiesSelections] = useState(form4014aDto.noPropertiesInClassification);
    const [nextId, setNextId] = useState(form4014aDto.properties.length + 1);
    const [filteredProperties, setFilteredProperties] = useState(filterPropertiesByClassification(form4014aDto.properties));
    const [propertyDeleteModalIsOpen, setPropertyDeleteModalIsOpen] = useState(false);
    const [submitFormModalIsOpen, setSubmitFormModalIsOpen] = useState(false);
    const [resubmitFormModalIsOpen, setResubmitFormModalIsOpen] = useState(false);
    const [acceptFormModalIsOpen, setAcceptFormModalIsOpen] = useState(false);
    const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
    const {selectedTab} = useTabNav();

    const isFormSubmittable = useMemo(() => {
      return Object.keys(noPropertiesSelections)
        .every((classification: string) => noPropertiesSelections[classification] || filteredProperties[classification].length > 0);
    }, [
      noPropertiesSelections,
      filteredProperties
    ]);

    const handleNoPropertiesSelection = useCallback(() => {
      // If it was selected let the user unselect with no prompts, otherwise display the confirmation modal
      if (selectedTab && noPropertiesSelections[selectedTab]) {
        const updatedClassificationsBooleanMap = ({
          ...noPropertiesSelections,
          [selectedTab as string]: false
        }) as forms.ClassificationsBooleanMap;
        setNoPropertiesSelections(updatedClassificationsBooleanMap);
        setHasUnsavedChanges(true);
      } else {
        setNoPropertiesModalIsOpen(true);
      }
    }, [
      setHasUnsavedChanges,
      noPropertiesSelections,
      selectedTab
    ]);

    const toggleNoPropertiesModal = useCallback((confirmNoProperties = false) => {
      if (noPropertiesModalIsOpen) {
        if (confirmNoProperties) {
          const noPropertiesSelectionsCopy = {...noPropertiesSelections, [selectedTab as string]: true};
          const filteredPropertiesCopy = {...filteredProperties, [selectedTab as string]: []};
          setFilteredProperties(filteredPropertiesCopy);
          setNoPropertiesSelections(noPropertiesSelectionsCopy);
          setNoPropertiesModalIsOpen(false);
          setHasUnsavedChanges(true);
        } else {
          setNoPropertiesModalIsOpen(false);
        }
      } else {
        setNoPropertiesModalIsOpen(true);
      }
    }, [
      noPropertiesModalIsOpen,
      selectedTab,
      setHasUnsavedChanges,
      noPropertiesSelections,
      filteredProperties
    ]);

    const getSaveableData = useCallback(() => {
      let properties: forms.Form4014aPropertyDto[] = [];
      Object.keys(filteredProperties).forEach(key =>
        properties = [...properties, ...filteredProperties[key]]
      );

      return {
        type: 'FORM_4014A' as const,
        noPropertiesInClassification: noPropertiesSelections,
        properties
      };
    }, [
      filteredProperties,
      noPropertiesSelections
    ]);

    const toggleSubmitModal = useCallback((confirmSubmit = false) => {
      if (confirmSubmit) {
        onSubmit(getSaveableData());
      }
      setSubmitFormModalIsOpen(!submitFormModalIsOpen);
    }, [
      onSubmit,
      submitFormModalIsOpen,
      getSaveableData
    ]);

    const toggleResubmitModal = useCallback((confirmResubmit = false) => {
      if (confirmResubmit) {
        onResubmit(getSaveableData());
      }
      setResubmitFormModalIsOpen(!resubmitFormModalIsOpen);
    }, [
      onResubmit,
      resubmitFormModalIsOpen,
      getSaveableData
    ]);

    const toggleCommentModal = useCallback(() => setCommentModalIsOpen(!commentModalIsOpen), [commentModalIsOpen]);

    const togglePropertyModal = useCallback((operation: 'Add' | 'Edit', property?: forms.Form4014aPropertyDto) => {
      if (!propertyModalIsOpen) {
        setPropertyModalIsOpen(true);
        setPropertyModalOperation(operation);
        if (operation === 'Add') {
          setSelectedProperty(undefined);
        } else if (operation === 'Edit' && property) {
          property = ({
            ...property,
            fieldInspectionOn: property.fieldInspectionOn,
            dateOfEntry: property.dateOfEntry
          } as forms.Form4014aPropertyDto);
          setSelectedProperty(property);
        }
      } else {
        // The conditional blocks handle if a successful add, successful edit, or if a cancel happened.
        setHasUnsavedChanges(true);
        if (operation === 'Add' && property) {
          const filteredPropertiesCopy = {...filteredProperties};
          property.id = nextId;
          (filteredPropertiesCopy[selectedTab as string] as forms.Form4014aPropertyDto[]).push(property);
          setFilteredProperties(filteredPropertiesCopy);
          setPropertyModalIsOpen(false);
          setNextId(nextId + 1);
        } else if (operation === 'Edit' && property) {

          const filteredPropertiesCopy = {...filteredProperties};
          const index = filteredPropertiesCopy[selectedTab as string]
            .findIndex((item: forms.Form4014aPropertyDto) => item.id === property?.id);
          if (index !== -1) {
            (filteredPropertiesCopy[selectedTab as string] as forms.Form4014aPropertyDto[])[index] = property;
          }
          setFilteredProperties(filteredPropertiesCopy);
          setPropertyModalIsOpen(false);
        } else {
          setPropertyModalIsOpen(false);
        }
      }
    }, [
      filteredProperties,
      nextId,
      selectedTab,
      propertyModalIsOpen,
      setHasUnsavedChanges
    ]);

    const toggleDeleteModal = useCallback((property?: forms.Form4014aPropertyDto) => {
      if (propertyDeleteModalIsOpen) {
        // If property has a value while the modal is open then attempt to delete it
        if (property) {
          setHasUnsavedChanges(true);
          const filteredPropertiesCopy = {...filteredProperties};
          const index = filteredProperties[selectedTab as string]
            .findIndex((item: forms.Form4014aPropertyDto) => item.id === property.id);
          filteredPropertiesCopy[selectedTab as string].splice(index, 1);
          setFilteredProperties(filteredPropertiesCopy);
        }

        setPropertyDeleteModalIsOpen(false);
      } else if (property) {
        setSelectedProperty({
          ...property,
          fieldInspectionOn: property.fieldInspectionOn,
          dateOfEntry: property.dateOfEntry
        } as forms.Form4014aPropertyDto);
        setPropertyDeleteModalIsOpen(true);
      }
    }, [
      filteredProperties,
      propertyDeleteModalIsOpen,
      selectedTab,
      setHasUnsavedChanges
    ]);

    const toggleAcceptFormModal = useCallback((confirmSubmit = false) => {
      if (confirmSubmit) {
        onAccept();
      }
      setAcceptFormModalIsOpen(!acceptFormModalIsOpen);
    }, [
      onAccept,
      acceptFormModalIsOpen
    ]);

    const totals = useMemo(() => {
      const propertiesToProcess = filteredProperties[selectedTab as string];
      const totals = {
        propertiesSelected: 0,
        improvedProperties: 0,
        inspectionsCompleted: 0,
        entriesCompleted: 0
      };
      propertiesToProcess.forEach((property: forms.Form4014aPropertyDto) => {
        totals.propertiesSelected += property.propertyNumber ? 1 : 0;
        totals.improvedProperties += property.vacantOrImproved === 'I' ? 1 : 0;
        totals.inspectionsCompleted += property.fieldInspectionOn ? 1 : 0;
        totals.entriesCompleted += property.dateOfEntry ? 1 : 0;
      });

      return totals;
    }, [
      filteredProperties,
      selectedTab
    ]);

    const handleSave = useCallback((afterSave?: () => void) => {
      return onSave(getSaveableData(), afterSave);
    }, [
      onSave,
      getSaveableData
    ]);

    const handleAmend = useCallback((amendmentRequest: AmendmentRequest) => {
      onAmend(amendmentRequest, getSaveableData());
    }, [
      onAmend,
      getSaveableData
    ]);

    const showReadOnlyView = useMemo(() => {
      return isStateUser || form.locked;
    }, [
      isStateUser,
      form.locked
    ]);


    const tableProps = useMemo(() => ({
      headers: [{
        title: 'Parcel Number',
        sortKey: 'propertyNumber',
        className: 'text-nowrap'
      }, {
        title: 'Property Address',
        sortKey: 'propertyAddress',
        className: 'text-nowrap'
      }, {
        title: 'Appraiser Initials',
        sortKey: 'appraiser',
        className: 'text-nowrap text-center'
      }, {
        title: 'Property Class',
        sortKey: 'propertyClassification',
        className: 'text-nowrap text-center'
      }, {
        title: 'Current Year AV',
        sortKey: 'currentYearAssessedValue',
        className: 'text-nowrap text-center'
      }, {
        title: 'Vacant or Improved',
        sortKey: 'vacantOrImproved',
        className: 'text-nowrap text-center'
      }, {
        title: 'Field Inspection',
        sortKey: 'fieldInspectionOn',
        className: 'text-nowrap text-center'
      }, {
        title: 'Data Entry Date',
        sortKey: 'dateOfEntry',
        className: 'text-nowrap text-center'
      }, {
        title: 'Comments',
        sortKey: 'comments',
        className: 'text-nowrap'
      },
        {
          title: 'Edit Parcel',
          className: 'text-nowrap text-center',
          sortKey: '',
          hide: showReadOnlyView
        }, {
          title: 'Delete Parcel',
          className: 'text-nowrap text-center',
          sortKey: '',
          hide: showReadOnlyView
        }
      ],
      headerRowClassName: 'text-primary',
      items: [...filteredProperties[selectedTab as string]],
      renderRow: (property: forms.Form4014aPropertyDto) => <Form4014aPropertyRow
        property={property}
        showReadOnlyView={showReadOnlyView}
        key={property.id}
        onEdit={() => togglePropertyModal('Edit', property)}
        onDelete={() => toggleDeleteModal(property)}
      />,
      initialSort: {
        sortKey: 'propertyNumber',
        direction: 'asc' as const
      },
      noResultsMessage: messages.NO_PARCELS_ADDED,
      paginatorConfig: {
        perPage: 100,
        recordName: 'parcels',
        allowShowAll: true
      }
    }), [
      selectedTab,
      showReadOnlyView,
      toggleDeleteModal,
      togglePropertyModal,
      filteredProperties
    ]);

    const shouldDisplayStateFormButtons = useMemo(() => {
      return isStateUser && form.status !== 'IN_PROGRESS';
    }, [
      isStateUser,
      form.status
    ]);

    return (
      <div className="Form4014a">
        <Card className="mb-3">
          <FormHeader form={form}/>
          <CardHeader className="nav-tabs-header">
            <TabNav/>
          </CardHeader>
          <CardBody>
            {!showReadOnlyView && <Row className="mb-4 no-properties-row">
              <Col xs="12" md="6">
                <CustomInput type="switch"
                             id="noParcelsSwitch"
                             name="noParcels"
                             label="No Parcels in this Classification"
                             onChange={handleNoPropertiesSelection}
                             checked={noPropertiesSelections[selectedTab as string] as boolean}
                             disabled={loading}/>
              </Col>
              <Col xs="12" md="6" className="d-flex justify-content-end">
                {!noPropertiesSelections[selectedTab as string] &&
                  <Button color="primary"
                          onClick={() => togglePropertyModal('Add')}
                          disabled={loading}>
                    Add Parcel
                  </Button>
                }
              </Col>
            </Row>}
            {!noPropertiesSelections[selectedTab as string] &&
              <>
                <CustomTable {...tableProps} />
                <div className="mt-4">
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th className="text-center text-primary"/>
                        <th className="text-center text-primary">Parcels Selected for Appraisal Study</th>
                        <th className="text-center text-primary">Improved Parcels</th>
                        <th className="text-center text-primary">Inspections Completed</th>
                        <th className="text-center text-primary">Entries Completed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center text-primary font-weight-bold">Totals</td>
                        <td className="text-center font-weight-bold">{formatInteger(totals.propertiesSelected)}</td>
                        <td className="text-center font-weight-bold">{formatInteger(totals.improvedProperties)}</td>
                        <td className="text-center font-weight-bold">{formatInteger(totals.inspectionsCompleted)}</td>
                        <td className="text-center font-weight-bold">{formatInteger(totals.entriesCompleted)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            }
          </CardBody>
        </Card>
        <FormHistoryTable items={form.formHistory}/>

        {!isStateUser && <>
          <UnsavedChangesWarningModal onSave={handleSave}/>
          <FormActionButtons submitDisabled={loading || !isFormSubmittable || showReadOnlyView}
                             saveDisabled={loading || showReadOnlyView}
                             onSave={handleSave}
                             submitButtonText={submitButtonText}
                             onToggleCommentModal={toggleCommentModal}
                             onToggleResubmitModal={() => toggleResubmitModal(false)}
                             onToggleSubmitModal={() => toggleSubmitModal(false)}/>

          <Form4014aPropertyModal isOpen={propertyModalIsOpen}
                                  operation={propertyModalOperation as ('Add' | 'Edit')}
                                  classificationFilter={selectedTab as string}
                                  property={selectedProperty}
                                  onToggle={togglePropertyModal}/>

          <NoPropertiesModal onToggle={toggleNoPropertiesModal}
                             isOpen={noPropertiesModalIsOpen}
                             selectedClassificationFilter={selectedTab as string}/>

          {selectedProperty && <ConfirmationModal isOpen={propertyDeleteModalIsOpen}
                                                  size="lg"
                                                  title="Delete Parcel"
                                                  confirmButtonColor="primary"
                                                  confirmButtonText="Yes"
                                                  cancelButtonText="No"
                                                  confirmCallback={() => toggleDeleteModal(selectedProperty)}
                                                  cancelCallback={() => toggleDeleteModal()}>
            <p>
              Are you sure you want to delete <span className="text-danger">{selectedProperty.propertyAddress}</span> from the {form.description} form for {form.localUnitDisplayName}?
            </p>
          </ConfirmationModal>}

          <FormModals form={form}
                      resubmitFormModalIsOpen={resubmitFormModalIsOpen}
                      submitFormModalIsOpen={submitFormModalIsOpen}
                      amendModalIsOpen={commentModalIsOpen}
                      onSubmit={() => toggleSubmitModal(true)}
                      onCancelSubmit={toggleSubmitModal}
                      onResubmit={() => toggleResubmitModal(true)}
                      onResubmitCancel={toggleResubmitModal}
                      onAmend={handleAmend}
                      toggleAmend={toggleCommentModal}
          />
        </>}

        {shouldDisplayStateFormButtons && <>
          <StateFormButtons loading={loading}
                            onReturnClick={toggleCommentModal}
                            onAcceptClick={() => toggleAcceptFormModal()}/>
          <StateFormModals onReturn={onReturn}
                           returnModalIsOpen={commentModalIsOpen}
                           onReturnCancel={toggleCommentModal}
                           onAccept={() => toggleAcceptFormModal(true)}
                           acceptModalIsOpen={acceptFormModalIsOpen}
                           onAcceptCancel={() => toggleAcceptFormModal()}
                           form={form}/>
        </>}
        <LocalUnitFormNavigator localUnitForm={form}
                                isStateUser={isStateUser}/>
      </div>
    );
  }
;

export default withTabNav(Form4014a, {tabs: PROPERTY_CLASSIFICATION_TABS});