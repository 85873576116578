import {useCallback, useMemo} from 'react';
import {Formik, FormikProps} from 'formik';
import {Button, Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

import {FormikCheckboxGroup, FormikDateInput, FormikInput, User} from '@reasoncorp/kyber-js';

import {report4030CertificationSchema} from '../../schemas/mega';
import {Report4030Dto} from '../../types/mega';
import {useUnsavedChangesWarning} from '../../hooks';

type Props = {
  report4030Dto: Report4030Dto
  onCertify: () => void
  isFosAnalystForCounty: boolean
  isFosSupervisorForCounty: boolean
  currentUser?: User
  loadingState: {
    processing: boolean
  }
}

const Report4030CertificationForm = ({
                                       report4030Dto,
                                       onCertify,
                                       isFosSupervisorForCounty,
                                       isFosAnalystForCounty,
                                       currentUser,
                                       loadingState
                                     }: Props) => {
  const {hasUnsavedChanges, setHasUnsavedChanges} = useUnsavedChangesWarning();

  const initialValues = useMemo(() => {
    if ((isFosAnalystForCounty && !report4030Dto?.certifiedByAnalystName) ||
      (isFosSupervisorForCounty && !report4030Dto?.certifiedBySupervisorName)) {
      return {
        name: currentUser?.fullName ?? '',
        certified: false,
        certifiedOn: ''
      };
    } else if (isFosAnalystForCounty && report4030Dto?.certifiedByAnalystName) {
      return {
        name: report4030Dto.certifiedByAnalystName,
        certified: report4030Dto.certifiedByAnalystId !== null,
        certifiedOn: report4030Dto.certifiedByAnalystOn
      };
    } else if (isFosSupervisorForCounty && report4030Dto?.certifiedBySupervisorName) {
      return {
        name: report4030Dto.certifiedBySupervisorName,
        certified: report4030Dto.certifiedBySupervisorId !== null,
        certifiedOn: report4030Dto.certifiedBySupervisorOn
      };
    } else {
      return {
        name: '',
        certified: false,
        certifiedOn: ''
      };
    }
  }, [
    currentUser,
    isFosSupervisorForCounty,
    isFosAnalystForCounty,
    report4030Dto
  ]);

  const handleCheckboxClick = useCallback(async (formikProps: FormikProps<any>) => {
    await formikProps.setFieldValue('certifiedOn', formikProps.values.certified ? '' : new Date());
  }, []);

  const isBypassed = useMemo(() => {
    return (isFosAnalystForCounty && report4030Dto?.bypassedAnalyst) ||
      (isFosSupervisorForCounty && report4030Dto?.bypassedSupervisor);
  }, [
    isFosSupervisorForCounty,
    isFosAnalystForCounty,
    report4030Dto
  ]);

  return <Formik initialValues={initialValues}
                 enableReinitialize={true}
                 validateOnMount={true}
                 onSubmit={onCertify}
                 validationSchema={report4030CertificationSchema(isBypassed)}>
    {(formikProps) => <>
      <Card className="mb-4">
        <CardHeader>
          Certification
        </CardHeader>
        <CardBody>
          {isFosAnalystForCounty && <strong>Report Completed By</strong>}
          {isFosSupervisorForCounty && <strong>Report Reviewed By</strong>}
          <Row className="mt-3">
            <Col md="6">
              <FormikInput name="name"
                           labelText="Signature"
                           disabled/>
            </Col>
            <Col>
              <FormikDateInput name="certifiedOn"
                               labelText="Signature Date"
                               disabled/>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormikCheckboxGroup checkboxes={[
                {
                  onChange: () => setHasUnsavedChanges(true),
                  disabled: isBypassed,
                  onClick: () => handleCheckboxClick(formikProps),
                  name: 'certified',
                  labelText: 'I agree and confirm that the signature above is the electronic representation of my original, handwritten signature when used on this document. I further understand that signing this document using my electronic signature will have the same legally binding effect as signing my signature using pen and paper.'
                }
              ]}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button color="success"
                  disabled={!formikProps.isValid || loadingState.processing || isBypassed || !hasUnsavedChanges}
                  onClick={formikProps.submitForm}>
            Certify
          </Button>
        </Col>
      </Row>
    </>}
  </Formik>;
};

export default Report4030CertificationForm;