import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, CustomInput, Row, Table} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  ConfirmationModal,
  CustomTable,
  FormikRadioGroup,
  ProgressIndicator,
  ProgressModal,
  TabNav,
  useAlerts,
  useTabNav,
  withTabNav
} from '@reasoncorp/kyber-js';

import {megApi} from '../../api';
import {masterSalesApi, outliersApi} from '../../api/mega';
import * as messages from '../../messages';
import {MasterSalesCsvModal, MasterSalesDataRow, MasterSalesModal} from '../../components/mega';
import {formatDecimal} from '../../utils';
import {mega} from '../../types';
import {useUnsavedChangesWarning} from '../../hooks';
import {UnsavedChangesWarningModal} from '../../components/shared';

const calculateRawRatio = (parcel: mega.MasterSalesData) => {
  if (parcel.adjustedSalesPrice === 0 || parcel.adjustedSalesPrice === null) {
    return 0;
  } else {
    return (parcel?.assessmentWhenSold ?? 0) / parcel.adjustedSalesPrice;
  }
};

const calculatePreRefinedRatio = (parcel: mega.MasterSalesData) => {
  if (parcel.adjustedSalesPrice === 0 || parcel.adjustedSalesPrice === null) {
    return 0;
  } else if (Number(parcel.psd4015Type) < 11) {
    return 'NU';
  } else {
    return (parcel?.assessmentWhenSold ?? 0) / parcel.adjustedSalesPrice;
  }
};

const findIdIndex = (arr: any[], id: number) => {
  let index = -1;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== undefined && arr[i].id === id) {
      index = i;
      break;
    }
  }

  return index;
};

const calculateParcelsData = (properties: mega.MasterSalesData[]) => properties.map(calculateParcelData);

const calculateParcelData = (parcel: mega.MasterSalesData) => {
  const rawRatio = calculateRawRatio(parcel);
  const preRefinedRatio = calculatePreRefinedRatio(parcel);
  return {
    ...parcel,
    rawRatio,
    preRefinedRatio
  };
};

const tabs = [
  {value: 'rawData', displayValue: 'Raw Data'},
  {value: 'salesIncluded', displayValue: 'Sales Included'}
];

const MasterSales = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, importing: false, saving: false});
  const [county, setCounty] = useState({displayName: ''});
  const {selectedTab} = useTabNav();
  const [csvModalIsOpen, setCsvModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState<number[]>([]);
  const [selectedParcel, setSelectedParcel] = useState<mega.MasterSalesData | undefined>(undefined);
  const [csvErrors, setCsvErrors] = useState<[string, string][]>([]);
  const [rawData, setRawData] = useState<mega.MasterSalesData[]>([]);
  const [salesIncludedData, setSalesIncludedData] = useState<mega.MasterSalesData[]>([]);
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {hasUnsavedChanges, setHasUnsavedChanges} = useUnsavedChangesWarning();
  const [currentOperation, setCurrentOperation] = useState('Add');
  const [masterSalesModalIsOpen, setMasterSalesModalIsOpen] = useState(false);
  const [recordsToCreate, setRecordsToCreate] = useState<mega.MasterSalesData[]>([]);
  const [recordsToUpdate, setRecordsToUpdate] = useState<mega.MasterSalesData[]>([]);
  const [imported, setImported] = useState(false);
  const [currentPageRawData, setCurrentPageRawData] = useState(0);
  const [currentPageSalesIncluded, setCurrentPageSalesIncluded] = useState(0);
  const [ignoreSort, setIgnoreSort] = useState(false);
  const [outliersData, setOutliersData] = useState<mega.Outliers | undefined>(undefined);
  const [outliersCalculations, setOutliersCalculations] = useState<mega.OutliersCalculations | undefined>(undefined);
  const [locked, setLocked] = useState(true);
  const [lockedModalIsOpen, setLockedModalIsOpen] = useState(false);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: county?.displayName ?? '', active: true}
  ]), [
    county,
    year,
    countyId
  ]);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        const [counties, masterSalesData, calculations, outliers] = await Promise.all([
          megApi.findCounties(true),
          masterSalesApi.find(countyId, year),
          outliersApi.findOutliersCalculations(countyId, year),
          outliersApi.findOutliers(countyId, year)
        ]);
        const currentCounty = counties.filter(county => county.id === Number(countyId))[0];
        if (currentCounty === undefined) {
          navigate('/state-portal/analytics');
        }
        const parcels = calculateParcelsData(masterSalesData) as mega.MasterSalesData[];
        setCounty(currentCounty);
        setRawData(parcels);
        setSalesIncludedData(parcels.filter(item => item.selected));
        setOutliersCalculations(calculations);
        setOutliersData(outliers);
        setLoadingState({loading: false, loadError: false, saving: false, importing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, saving: false, importing: false});
      }
    };

    void initialLoad();
  }, [countyId, year, showErrorAlert, navigate]);

  const handleToggleSalesIncluded = useCallback((parcel: mega.MasterSalesData) => {
    const rawDataIndex = findIdIndex(rawData, parcel?.id ?? 0);
    const salesIncludedIndex = findIdIndex(salesIncludedData, parcel?.id ?? 0);

    if (rawData[rawDataIndex].selected) {
      const rawDataClone = rawData.slice();
      rawDataClone[rawDataIndex].selected = false;
      setRawData(rawDataClone);
      salesIncludedData.splice(salesIncludedIndex, 1);
    } else {
      rawData[rawDataIndex].selected = true;
      setSalesIncludedData([parcel, ...salesIncludedData]);
    }
    parcel.selected = rawData[rawDataIndex].selected;
    // either add parcel in recordsToUpdate, or update the current item
    if (parcel.unsaved) {
      const recordsToCreateIndex = findIdIndex(recordsToCreate, parcel?.id ?? 0);
      if (recordsToCreateIndex === -1) {
        setRecordsToCreate([...recordsToCreate, rawData[rawDataIndex]]);
      } else {
        const recordsToCreateClone = recordsToCreate.slice();
        recordsToCreateClone[recordsToCreateIndex] = parcel;
        setRecordsToCreate(recordsToCreateClone);
      }
    } else {
      const recordsToUpdateIndex = findIdIndex(recordsToUpdate, parcel?.id ?? 0);
      if (recordsToUpdateIndex === -1) {
        setRecordsToUpdate([...recordsToUpdate, parcel]);
      } else {
        //  TODO bug
        const recordsToUpdateClone = recordsToUpdate.slice();
        recordsToUpdateClone[recordsToUpdateIndex] = parcel;
        setRecordsToUpdate(recordsToUpdateClone);
      }
    }
    setHasUnsavedChanges(true);
  }, [
    setHasUnsavedChanges,
    rawData,
    recordsToCreate,
    recordsToUpdate,
    salesIncludedData
  ]);

  const toggleCsvModal = useCallback(() => {
    setCsvModalIsOpen(!csvModalIsOpen);
    setCsvErrors([]);
  }, [
    csvModalIsOpen
  ]);

  const handleOpenDeleteModal = useCallback((parcel: mega.MasterSalesData) => {
    setSelectedParcel(parcel);
    setDeleteModalIsOpen(true);
  }, []);

  const handleImport = useCallback(async (csvFileUpload: mega.CsvFileUpload,
                                          formikActions: FormikHelpers<mega.CsvFileUpload>) => {
    setLoadingState({...loadingState, importing: true});

    try {
      const formData = new FormData();
      formData.set('csvFile', csvFileUpload.csvFile as File);
      const {masterSalesData, errors} = await masterSalesApi.importMasterSales(countyId, year, formData);
      if (errors == null || Object.keys(errors).length === 0) {
        const parcels = calculateParcelsData(masterSalesData).map((parcel, index) => {
          return {...parcel, unsaved: true, selected: Number(parcel.psd4015Type) >= 11, id: index};
        });
        setRecordsToCreate(parcels);
        setRawData(parcels);
        setSalesIncludedData(parcels.filter(parcel => parcel.selected));
        formikActions.resetForm();
        toggleCsvModal();
        setImported(true);
        setHasUnsavedChanges(true);
        setCurrentPageRawData(0);
        setCurrentPageSalesIncluded(0);
      } else {
        setCsvErrors(Object.entries(errors));
      }
    } catch (error) {
      const errorWithType = error as {data?: {status: number, validationMessages: string[]}};
      if (errorWithType.data && errorWithType.data.status === 422) {
        if (errorWithType.data.validationMessages) {
          formikActions.setErrors({csvFile: messages.BAD_FILE_FORMAT_CSV});
        }
        formikActions.setSubmitting(false);
      } else {
        formikActions.resetForm();
        toggleCsvModal();
        showErrorAlert(messages.API_FAILURE, true);
      }
    } finally {
      setLoadingState({...loadingState, importing: false});
    }
  }, [
    setHasUnsavedChanges,
    countyId,
    loadingState,
    showErrorAlert,
    toggleCsvModal,
    year
  ]);

  const handleSave = useCallback(async (afterSave?: () => void) => {
    setLoadingState((prevState) => ({...prevState, saving: true}));

    // Use a function defined inside actual callback as a workaround to react state updates being asynchronous
    const save = async () => {
      try {
        if (idsToDelete.length > 0) {
          await masterSalesApi.deleteMasterSales(idsToDelete.join(','), countyId);
          setIdsToDelete([]);
        }

        if (recordsToCreate.length > 0) {
          const masterSalesData = recordsToCreate.map(item => {
            const {rawRatio, refinedRatio, preRefinedRatio, ...parcel} = item;
            return parcel;
          });
          await masterSalesApi.save(countyId, year, {masterSalesData, imported});
          setRecordsToCreate([]);
          setImported(false);
        }

        if (recordsToUpdate.length > 0) {
          const masterSalesData = recordsToUpdate.map(item => {
            const {rawRatio, refinedRatio, preRefinedRatio, ...parcel} = item;
            return parcel;
          });
          await masterSalesApi.update(countyId, {masterSalesData, imported: false});
          setRecordsToUpdate([]);
        }

        const outliers = await outliersApi.updateOutliers(countyId, year, outliersData?.enabled ?? false, outliersData?.studyLength ?? 1);
        const calculations = await outliersApi.findOutliersCalculations(countyId, year);
        const masterSalesData = await masterSalesApi.find(countyId, year);
        const parcels = calculateParcelsData(masterSalesData);

        setRawData(parcels);
        setSalesIncludedData(parcels.filter(item => item.selected));
        setOutliersData(outliers);
        setOutliersCalculations(calculations);
        setCurrentPageRawData(0);
        setCurrentPageSalesIncluded(0);
        setIgnoreSort(false);
        showSuccessAlert(messages.REPORT_SAVE_SUCCESSFUL);

        if (afterSave) {
          afterSave();
        }
        setHasUnsavedChanges(false);
      } catch (error) {
        showErrorAlert(messages.REPORT_SAVE_FAILURE, true);
      } finally {
        setLoadingState({...loadingState, saving: false});
      }
    };

    void save();
  }, [
    setHasUnsavedChanges,
    loadingState,
    year,
    countyId,
    idsToDelete,
    imported,
    outliersData,
    recordsToCreate,
    recordsToUpdate,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleUpdate = useCallback((parcel: mega.MasterSalesData) => {
    const calculatedParcel = calculateParcelData(parcel);
    if (parcel.unsaved) {
      const recordsToCreateIndex = findIdIndex(recordsToCreate, parcel?.id ?? 0);
      if (recordsToCreateIndex !== -1) {
        const recordsToCreateClone = recordsToCreate.slice();
        recordsToCreateClone[recordsToCreateIndex] = calculatedParcel;
        setRecordsToCreate(recordsToCreateClone);
      }
    } else {
      if (findIdIndex(recordsToUpdate, parcel?.id ?? 0) === -1) {
        setRecordsToUpdate([...recordsToUpdate, calculatedParcel] as mega.MasterSalesData[]);
      }
    }

    const salesIncludedIndex = findIdIndex(salesIncludedData, parcel?.id ?? 0);
    const rawDataIndex = findIdIndex(rawData, parcel?.id ?? 0);

    if (Number(parcel.psd4015Type) >= 11 && parcel.selected) {
      if (salesIncludedIndex === -1) {
        setSalesIncludedData([calculatedParcel, ...salesIncludedData] as mega.MasterSalesData[]);
      } else {
        salesIncludedData[salesIncludedIndex] = calculatedParcel as mega.MasterSalesData;
      }
    } else if (Number(parcel.psd4015Type) >= 11 && !parcel.selected) {
      if (Number(rawData[rawDataIndex].psd4015Type) < 11) {
        calculatedParcel.selected = true;
        const salesIncludedDataClone = salesIncludedData.slice();
        setSalesIncludedData([calculatedParcel, ...salesIncludedDataClone]);
      } else {
        if (salesIncludedIndex !== -1) {
          salesIncludedData.splice(salesIncludedIndex, 1);
        }
      }
    } else if (Number(parcel.psd4015Type) < 11 && parcel.selected) {
      if (Number(rawData[rawDataIndex].psd4015Type) >= 11 && rawData[rawDataIndex].selected) {
        calculatedParcel.selected = false;
        if (salesIncludedIndex !== -1) {
          salesIncludedData.splice(salesIncludedIndex, 1);
        }
      }
    } else if (Number(parcel.psd4015Type) < 11 && !parcel.selected) {
      calculatedParcel.selected = false;
      if (salesIncludedIndex !== -1) {
        salesIncludedData.splice(salesIncludedIndex, 1);
      }
    }

    rawData[rawDataIndex] = calculatedParcel as mega.MasterSalesData;

    setHasUnsavedChanges(true);
    setMasterSalesModalIsOpen(false);
  }, [
    setHasUnsavedChanges,
    salesIncludedData,
    rawData,
    recordsToCreate,
    recordsToUpdate
  ]);

  const handleCreate = useCallback((parcel: mega.MasterSalesData) => {
    parcel.unsaved = true;
    parcel.id = Number(Number(new Date()).toString().substring(7, 13));
    const newParcel = calculateParcelData(parcel);
    setRecordsToCreate([...recordsToCreate, newParcel] as mega.MasterSalesData[]);
    setRawData([newParcel, ...rawData] as mega.MasterSalesData[]);
    setHasUnsavedChanges(true);
    setCurrentPageRawData(0);
    setCurrentPageSalesIncluded(0);
    setIgnoreSort(true);
    setMasterSalesModalIsOpen(false);
  }, [
    setHasUnsavedChanges,
    rawData,
    recordsToCreate
  ]);

  const handleEditClick = useCallback((parcel: mega.MasterSalesData) => {
    setCurrentOperation('Edit');
    setSelectedParcel(parcel);
    setMasterSalesModalIsOpen(true);
  }, []);

  const handleAddClick = useCallback(() => {
    setCurrentOperation('Add');
    setSelectedParcel(undefined);
    setMasterSalesModalIsOpen(true);
  }, []);

  const handleCsvModalClose = useCallback((formikProps: FormikProps<any>) => {
    setMasterSalesModalIsOpen(false);
    formikProps.resetForm();
  }, []);

  const handleSetStudyLength = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setHasUnsavedChanges(true);
    setOutliersData({...outliersData, studyLength: Number(e.target.value)} as mega.Outliers);
  }, [
    setHasUnsavedChanges,
    outliersData
  ]);

  const handleIncludeOutliers = useCallback(() => {
    setHasUnsavedChanges(true);
    setOutliersData({...outliersData, enabled: !outliersData?.enabled} as mega.Outliers);
  }, [
    setHasUnsavedChanges,
    outliersData
  ]);

  const handleDelete = useCallback((parcelToDelete: mega.MasterSalesData) => {
    // use findIdIndex vs built in findIndex or filter.
    const rawDataIndex = findIdIndex(rawData, parcelToDelete?.id ?? 0);
    const salesIncludedDataIndex = findIdIndex(salesIncludedData, parcelToDelete?.id ?? 0);
    const recordsToCreateIndex = findIdIndex(recordsToCreate, parcelToDelete?.id ?? 0);
    const recordsToUpdateIndex = findIdIndex(recordsToUpdate, parcelToDelete?.id ?? 0);
    // use delete to access rawData/salesIncludedData/recordsToCreate,recordsToUpdate with index key.
    rawData.splice(rawDataIndex, 1);
    salesIncludedData.splice(salesIncludedDataIndex, 1);
    recordsToCreate.splice(recordsToCreateIndex, 1);
    recordsToUpdate.splice(recordsToUpdateIndex, 1);

    // if parcel is persisted, store for save call to delete
    if (!parcelToDelete.unsaved) {
      setHasUnsavedChanges(true);
      if (parcelToDelete.id) {
        setIdsToDelete([...idsToDelete, parcelToDelete.id]);
      }
    }
    setDeleteModalIsOpen(false);
  }, [
    setHasUnsavedChanges,
    idsToDelete,
    recordsToUpdate,
    recordsToCreate,
    rawData,
    salesIncludedData
  ]);

  const renderOutliersRow = useMemo(() => ([key, classificationData]: [string, mega.OutliersCalculationsRow]) => {
    const classificationCodes = key + ' - ' + parseInt(key) / 100 + 49;
    return <tr>
      <td className="text-left align-middle font-weight-bold text-primary">
        {classificationData.classificationName}
      </td>
      <td className="text-center align-middle">
        {classificationCodes}
      </td>
      <td className="text-center align-middle">
        {formatDecimal(classificationData.meanRatio, 4)}
      </td>
      <td className="text-center align-middle">
        {formatDecimal(classificationData.standardDeviation2, 4)}
      </td>
      <td className="text-center align-middle">
        {formatDecimal(classificationData.upperLimit, 2, true)}
      </td>
      <td className="text-center align-middle">
        {formatDecimal(classificationData.lowerLimit, 2, true)}
      </td>
    </tr>;
  }, []);

  const handleToggleLocked = useCallback(() => {
    setLockedModalIsOpen(false);
    setLocked(!locked);
    showSuccessAlert(locked ? messages.REPORT_UNLOCK_SUCCESSFUL : messages.REPORT_LOCK_SUCCESSFUL);
  }, [
    locked,
    showSuccessAlert
  ]);

  const masterSalesTableProps = useMemo(() => ({
    headers: [
      {title: 'Select Parcel', className: 'align-middle text-primary text-center bg-light'},
      {
        title: 'PSD L-4015 Type',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'psd4015Type'
      },
      {
        title: 'Verified',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'verified'
      },
      {
        title: 'Parcel Code',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'propertyId'
      },
      {
        title: 'Unit Number',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'unitNumber'
      },
      {
        title: 'Property Class',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'propertyClassification'
      },
      {
        title: 'Sale Date',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'saleOn'
      },
      {
        title: 'Liber Page',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'liberPage'
      },
      {
        title: 'Grantor',
        className: 'align-middle text-primary text-left bg-light',
        sortKey: ignoreSort ? undefined : 'grantor'
      },
      {
        title: 'Grantee',
        className: 'align-middle text-primary text-left bg-light',
        sortKey: ignoreSort ? undefined : 'grantee'
      },
      {
        title: 'Instrument',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'instrument'
      },
      {
        title: 'Assessment When Sold',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'assessmentWhenSold'
      },
      {
        title: 'Unadjusted Sales Price',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'unadjustedSalesPrice'
      },
      {
        title: 'Adjusted Sales Price',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'adjustedSalesPrice'
      },
      {
        title: 'CED L-4015 Type',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'ced4015Type'
      },
      {title: 'CED Comments', className: 'align-middle text-primary bg-light'},
      {title: 'PSD Comments', className: 'align-middle text-primary bg-light'},
      {
        title: 'Raw Ratio',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'rawRatio'
      },
      {
        title: 'Pre Refined Ratio',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'preRefinedRatio'
      },
      {
        title: 'Refined Ratio',
        className: 'align-middle text-primary text-center bg-light',
        sortKey: ignoreSort ? undefined : 'refinedRatio'
      },
      {
        title: 'Edit Parcel',
        className: 'align-middle text-primary text-center bg-light',
        hide: selectedTab !== 'rawData'
      },
      {
        title: 'Delete Parcel',
        className: 'align-middle text-primary text-center bg-light',
        hide: selectedTab !== 'rawData'
      }
    ],
    renderRow: (isRawData = false) => (item: mega.MasterSalesData) =>
      <MasterSalesDataRow item={item}
                          disabled={locked}
                          onToggleSalesIncluded={handleToggleSalesIncluded}
                          onEditClick={handleEditClick}
                          onDeleteClick={handleOpenDeleteModal}
                          highlight={outliersData !== undefined && (outliersData?.enabled ?? false) && (item.refinedRatio === 'SOA' || item.refinedRatio === 'SOB')}
                          key={item?.id?.toString()}
                          isRawData={isRawData}/>
  }), [
    handleEditClick,
    handleOpenDeleteModal,
    handleToggleSalesIncluded,
    ignoreSort,
    locked,
    outliersData,
    selectedTab
  ]);

  const selectedItems = useMemo(() => {
    const items: {[itemId: string]: boolean} = {};
    salesIncludedData.forEach((item) => {
      items[`selected-${item.id}`] = true;
    });

    return items;
  }, [
    salesIncludedData
  ]);

  return <Container fluid className="MasterSales">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Row className="mb-3">
        <Col>
          <CustomInput type="switch"
                       id="lockedSwitch"
                       name="lockReport"
                       label="Locked"
                       aria-label="Locked"
                       onChange={() => setLockedModalIsOpen(true)}
                       checked={locked}/>
        </Col>
      </Row>
      <Card className="mb-4">
        <CardHeader>Outlier Analysis</CardHeader>
        <CardBody>
          <Row className="mb-1">
            <Col>
              <CustomInput type="switch"
                           id="includeOutliersSwitch"
                           name="includeOutliers"
                           label="Include Outliers"
                           aria-label="Include Outliers"
                           onChange={handleIncludeOutliers}
                           disabled={locked}
                           checked={outliersData?.enabled}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Formik initialValues={{studyLength: outliersData?.studyLength?.toString()}}
                      onSubmit={async () => null}
                      enableReinitialize={true}>
                {(_) => (
                  <FormikRadioGroup name="studyLength"
                                    inline={true}
                                    onChange={handleSetStudyLength}
                                    disabled={locked}
                                    radioButtons={[
                                      {labelText: '1 Year Study', value: '1', ariaLabel: '1 Year Study'},
                                      {labelText: '2 Year Study', value: '2', ariaLabel: '2 Year Study'}
                                    ]}
                  />)}
              </Formik>
            </Col>
          </Row>
          <Table bordered responsive>
            <thead>
              <tr className="bg-light">
                <th className="text-primary left align-middle">Classification</th>
                <th className="text-primary text-center align-middle">Classification Codes</th>
                <th className="text-primary text-center align-middle">Mean Ratio</th>
                <th className="text-primary text-center align-middle">2 Standard Deviations</th>
                <th className="text-primary text-center align-middle">Upper Limit</th>
                <th className="text-primary text-center align-middle">Lower Limit</th>
              </tr>
            </thead>
            <tbody>
              {outliersCalculations && Object.entries(outliersCalculations).map(renderOutliersRow)}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card className="mb-4">
        <CardHeader>Master Sales</CardHeader>
        <CardHeader className="nav-tabs-header">
          <TabNav/>
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Col className="d-flex justify-content-end">
              <Button color="primary"
                      disabled={selectedTab === 'salesIncluded' || locked}
                      onClick={() => setCsvModalIsOpen(true)}
                      className="mr-2">
                Import Data
              </Button>
              <Button color="primary"
                      onClick={() => handleAddClick()}
                      disabled={selectedTab === 'salesIncluded' || locked}>
                Add Record
              </Button>
            </Col>
          </Row>
          <div className="table-fixed">
            <Formik initialValues={selectedItems}
                    onSubmit={async () => false}
                    enableReinitialize={true}>
              <>
                {/* use display: none vs conditional re-rendering for performance  */}
                <div style={{display: selectedTab === 'rawData' ? '' : 'none'}}>
                  <CustomTable headers={masterSalesTableProps.headers}
                               items={rawData}
                               renderRow={masterSalesTableProps.renderRow(true)}
                               paginatorConfig={{
                                 perPage: 500,
                                 currentPage: currentPageRawData,
                                 setCurrentPage: setCurrentPageRawData
                               }}
                               noResultsMessage="No raw data."
                               initialSort={{sortKey: 'psd4015Type', direction: 'asc'}}
                  />
                </div>
                {/* use display: none vs conditional re-rendering for performance  */}
                <div style={{display: selectedTab === 'salesIncluded' ? '' : 'none'}}>
                  <CustomTable headers={masterSalesTableProps.headers}
                               items={salesIncludedData}
                               renderRow={masterSalesTableProps.renderRow(false)}
                               paginatorConfig={{
                                 perPage: 500,
                                 currentPage: currentPageSalesIncluded,
                                 setCurrentPage: setCurrentPageSalesIncluded
                               }}
                               noResultsMessage="No sales included."
                               initialSort={{sortKey: 'psd4015Type', direction: 'asc'}}
                  />
                </div>
              </>
            </Formik>
          </div>
        </CardBody>
      </Card>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button color="success"
                  disabled={loadingState.saving || !hasUnsavedChanges || locked}
                  onClick={() => handleSave()}>
            Save
          </Button>
        </Col>
      </Row>
    </>}

    <UnsavedChangesWarningModal onSave={handleSave}/>

    <MasterSalesCsvModal isOpen={csvModalIsOpen}
                         errors={csvErrors}
                         onSubmit={handleImport}
                         onToggle={() => toggleCsvModal()}/>
    {selectedParcel && <ConfirmationModal isOpen={deleteModalIsOpen}
                                          title="Delete Parcel"
                                          confirmCallback={() => handleDelete(selectedParcel)}
                                          cancelCallback={() => setDeleteModalIsOpen(false)}
                                          confirmButtonColor="primary"
                                          confirmButtonText="Yes"
                                          cancelButtonText="No">
      <p>
        Are you sure you want to delete <span className="text-danger">({selectedParcel.propertyId})</span> from the Master Sales Raw Data?
      </p>
    </ConfirmationModal>}

    <MasterSalesModal isOpen={masterSalesModalIsOpen}
                      operation={currentOperation as ('Add' | 'Edit')}
                      onUpdate={handleUpdate}
                      onCreate={handleCreate}
                      countyId={Number(countyId)}
                      parcel={selectedParcel}
                      onToggle={handleCsvModalClose}/>

    <ProgressModal isOpen={loadingState.saving}
                   title="Saving Report"
                   content="Report is being saved. Please do not refresh the page, as this could take a few moments."/>

    <ConfirmationModal size="lg"
                       isOpen={lockedModalIsOpen}
                       title={locked ? 'Confirm Unlocking Report' : 'Confirm Locking Report'}
                       confirmCallback={handleToggleLocked}
                       cancelCallback={() => setLockedModalIsOpen(false)}
                       confirmButtonColor="success"
                       confirmButtonText="Yes"
                       cancelButtonText="No">
      <p>
        Are you sure you want to {locked ? 'unlock' : 'lock'} the <span className="text-danger">Master Sales</span> for <span className="text-danger">{county?.displayName}</span>?
      </p>
    </ConfirmationModal>
  </Container>;
};

export default withTabNav(MasterSales, {tabs});