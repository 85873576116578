import {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {Card, CardBody, CardHeader, Col, CustomInput, Form, Row} from 'reactstrap';
import {Formik, FormikProps} from 'formik';

import {FormikRadioGroup, TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {PROPERTY_CLASSIFICATION_TABS} from '../../utils';
import {
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  LocalUnitFormNavigator,
  NoPropertiesModal,
  StateFormButtons,
  StateFormModals,
  UnsavedChangesWarningModal
} from '../../components/shared';
import {form4017Schema} from '../../schemas';
import {AmendmentRequest, forms, LocalUnitFormDto, ReturnRequest} from '../../types';
import * as form4017Utils from '../../utils/form4017Utils';
import {
  Form4017AdjustmentModifierCards,
  Form4017Month12Table,
  Form4017Month24Table
} from '../../components/forms/form4017';
import {useUnsavedChangesWarning} from '../../hooks';

type Props = {
  form: LocalUnitFormDto
  loading: boolean
  isStateUser: boolean
  submitButtonText: string
  onReturn: (returnRequest: ReturnRequest) => void
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.LocalUnitFormData) => void
  onSubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onResubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onSave: (localUnitFormData: forms.LocalUnitFormData, afterSave?: () => void) => Promise<void>,
  onAccept: () => void,
  isLocalUnitUser: boolean,
  classificationStudyYears: forms.ClassificationStudyYears
  setClassificationStudyYears: (classificationStudyYears: forms.ClassificationStudyYears) => void
}

const Form4017 = ({
                    form,
                    loading,
                    isStateUser,
                    submitButtonText,
                    onReturn,
                    onAmend,
                    onSubmit,
                    onResubmit,
                    onSave,
                    onAccept,
                    isLocalUnitUser,
                    classificationStudyYears,
                    setClassificationStudyYears
                  }: Props) => {
  const {setHasUnsavedChanges} = useUnsavedChangesWarning();

  const form4017Dto = useMemo(() => (
    isStateUser && form.latestSubmissionData !== null ? form.latestSubmissionData : form.data
  ) as forms.Form4017Dto, [form, isStateUser]);

  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [resubmitModalIsOpen, setResubmitModalIsOpen] = useState(false);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const [noPropertiesModalIsOpen, setNoPropertiesModalIsOpen] = useState(false);
  const [noPropertiesSelections, setNoPropertiesSelections] = useState(form4017Dto.noPropertiesInClassification);
  const {selectedTab} = useTabNav();

  const getSaveableData = useCallback((formikProps: FormikProps<any>) => {
    const {values} = formikProps;
    return {
      type: 'FORM_4017_4047' as const,
      noPropertiesInClassification: noPropertiesSelections,
      classificationStudyYears: classificationStudyYears ? form4017Utils.mapStudyYears(classificationStudyYears) : {},
      classifications: values.classifications
    };
  }, [
    noPropertiesSelections,
    classificationStudyYears
  ]);

  const handleSetStudyLength = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setHasUnsavedChanges(true);
    setClassificationStudyYears({
      ...classificationStudyYears,
      [selectedTab as string]: e.target.value
    });
  }, [
    setClassificationStudyYears,
    setHasUnsavedChanges,
    classificationStudyYears,
    selectedTab
  ]);

  const toggleSubmitModal = useCallback((confirmSubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmSubmit && formikProps) {
      onSubmit(getSaveableData(formikProps));
    }
    setSubmitModalIsOpen(!submitModalIsOpen);
  }, [
    onSubmit,
    getSaveableData,
    submitModalIsOpen
  ]);

  const handleAmend = useCallback((formikProps: FormikProps<any>, amendmentRequest: AmendmentRequest) => {
    onAmend(amendmentRequest, getSaveableData(formikProps));
  }, [
    onAmend,
    getSaveableData
  ]);

  const toggleCommentModal = useCallback(() => setCommentModalIsOpen(!commentModalIsOpen), [commentModalIsOpen]);

  const toggleResubmitModal = useCallback((confirmResubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmResubmit && formikProps) {
      onResubmit(getSaveableData(formikProps));
    }
    setResubmitModalIsOpen(!resubmitModalIsOpen);
  }, [
    onResubmit,
    getSaveableData,
    resubmitModalIsOpen
  ]);

  const toggleAcceptModal = useCallback((confirmAccept = false) => {
    if (confirmAccept) {
      onAccept();
    }
    setAcceptModalIsOpen(!acceptModalIsOpen);
  }, [
    onAccept,
    acceptModalIsOpen
  ]);

  const handleNoPropertiesSelection = useCallback(() => {
    // If it was selected let the user unselect with no prompts, otherwise display the confirmation modal
    if (selectedTab && noPropertiesSelections[selectedTab]) {
      const updatedClassificationsBooleanMap = ({
        ...noPropertiesSelections,
        [selectedTab as string]: false
      }) as forms.ClassificationsBooleanMap;
      setNoPropertiesSelections(updatedClassificationsBooleanMap);

      setHasUnsavedChanges(true);
      const classificationStudyYearsCopy = {...classificationStudyYears};
      classificationStudyYearsCopy[selectedTab] = null;
      setClassificationStudyYears(classificationStudyYearsCopy);
    } else {
      setNoPropertiesModalIsOpen(true);
    }
  }, [
    setHasUnsavedChanges,
    setClassificationStudyYears,
    classificationStudyYears,
    selectedTab,
    noPropertiesSelections
  ]);

  const toggleNoPropertiesModal = useCallback((formikProps: FormikProps<any>) =>
    (confirmNoProperties = false) => {
      if (noPropertiesModalIsOpen) {
        if (confirmNoProperties && selectedTab) {
          const noPropertiesSelectionsCopy = {...noPropertiesSelections, [selectedTab as string]: true};
          void formikProps.setFieldValue(
            `classifications['${selectedTab}']`,
            form4017Utils.mapClassificationData(form4017Dto, selectedTab)
          );

          setNoPropertiesSelections(noPropertiesSelectionsCopy);
          setNoPropertiesModalIsOpen(false);
          setHasUnsavedChanges(true);

          const classificationStudyYearsCopy = {...classificationStudyYears} as forms.ClassificationStudyYears;
          classificationStudyYearsCopy[selectedTab as string] = null;
          setClassificationStudyYears(classificationStudyYearsCopy);
        } else {
          setNoPropertiesModalIsOpen(false);
        }
      } else {
        setNoPropertiesModalIsOpen(true);
      }
    }, [
    setClassificationStudyYears,
    setHasUnsavedChanges,
    noPropertiesModalIsOpen,
    noPropertiesSelections,
    selectedTab,
    form4017Dto,
    classificationStudyYears
  ]);

  const handleSave = useCallback((formikProps: FormikProps<any>, afterSave?: () => void) => {
    onSave(getSaveableData(formikProps), afterSave);
  }, [
    onSave,
    getSaveableData
  ]);

  const shouldDisplayFormActionButtons = useMemo(() => {
    return !isStateUser && !isLocalUnitUser;
  }, [
    isStateUser,
    isLocalUnitUser
  ]);

  const shouldDisplayStateFormButtons = useMemo(() => {
    return isStateUser && form.status !== 'IN_PROGRESS';
  }, [
    isStateUser,
    form.status
  ]);

  const showReadOnlyView = useMemo(() => {
    return isStateUser || isLocalUnitUser || form.locked;
  }, [
    isStateUser,
    isLocalUnitUser,
    form.locked
  ]);

  return (
    <div className="Form4017">
      {selectedTab && <Formik validationSchema={form4017Schema(noPropertiesSelections)}
                              initialValues={form4017Utils.getInitialValues(form4017Dto, classificationStudyYears)}
                              enableReinitialize={true}
                              onSubmit={async () => null}
                              validateOnMount={true}>
        {(formikProps: FormikProps<any>) => {
          const totals = form4017Utils.calculateTotals(formikProps, selectedTab);
          const currentClassificationValues = formikProps.values.classifications[selectedTab as string];

          return <>
            <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
              <Card className="mb-3">
                <FormHeader form={form}/>
                <CardHeader className="nav-tabs-header">
                  <TabNav/>
                </CardHeader>
                <CardBody>
                  {!showReadOnlyView && <>
                    <Row className="mb-2">
                      <Col xs="12">
                        <CustomInput type="switch"
                                     id="noParcelsSwitch"
                                     name="noParcels"
                                     label="No Form 2793 for this Classification"
                                     aria-label="No Form 2793 for this Classification"
                                     onChange={handleNoPropertiesSelection}
                                     checked={noPropertiesSelections[selectedTab as string] as boolean}
                                     disabled={loading}/>
                      </Col>
                    </Row>
                  </>}
                  {!noPropertiesSelections[selectedTab as string] && <Row>
                    <Col xs="12">
                      <FormikRadioGroup name={`classificationStudyYears['${selectedTab}']`}
                                        inline={true}
                                        disabled={showReadOnlyView}
                                        onChange={handleSetStudyLength}
                                        radioButtons={[
                                          {labelText: '1 Year Study', value: '1', ariaLabel: '1 Year Study'},
                                          {labelText: '2 Year Study', value: '2', ariaLabel: '2 Year Study'}
                                        ]}
                      />
                    </Col>
                  </Row>}
                  {!noPropertiesSelections[selectedTab as string] && selectedTab && <>
                    <Form4017AdjustmentModifierCards year={form.year}
                                                     selectedTab={selectedTab}
                                                     currentClassificationValues={currentClassificationValues}
                                                     totals={totals}
                                                     showReadOnlyView={showReadOnlyView}
                                                     setHasUnsavedChanges={setHasUnsavedChanges}/>
                    <Form4017Month24Table year={form.year}
                                          selectedTab={selectedTab}
                                          currentClassificationValues={currentClassificationValues}
                                          totals={totals}
                                          showReadOnlyView={showReadOnlyView}
                                          setHasUnsavedChanges={setHasUnsavedChanges}/>
                    <Form4017Month12Table year={form.year}
                                          selectedTab={selectedTab}
                                          currentClassificationValues={currentClassificationValues}
                                          totals={totals}
                                          showReadOnlyView={showReadOnlyView}
                                          setHasUnsavedChanges={setHasUnsavedChanges}/>
                  </>}
                </CardBody>
              </Card>

              <FormHistoryTable items={form.formHistory}/>

              {shouldDisplayFormActionButtons && <>
                <UnsavedChangesWarningModal onSave={(afterSave) => handleSave(formikProps, afterSave)}/>

                <FormActionButtons saveDisabled={loading || formikProps.isSubmitting || showReadOnlyView}
                                   submitDisabled={loading || !formikProps.isValid || formikProps.isSubmitting || showReadOnlyView}
                                   submitButtonText={submitButtonText}
                                   onSave={() => handleSave(formikProps)}
                                   onToggleSubmitModal={() => toggleSubmitModal()}
                                   onToggleCommentModal={() => toggleCommentModal()}
                                   onToggleResubmitModal={() => toggleResubmitModal(false)}/>

                <NoPropertiesModal onToggle={toggleNoPropertiesModal(formikProps)}
                                   isOpen={noPropertiesModalIsOpen}
                                   selectedClassificationFilter={selectedTab as string}/>
                <FormModals form={form}
                            resubmitFormModalIsOpen={resubmitModalIsOpen}
                            submitFormModalIsOpen={submitModalIsOpen}
                            amendModalIsOpen={commentModalIsOpen}
                            onSubmit={() => toggleSubmitModal(true, formikProps)}
                            onCancelSubmit={() => toggleSubmitModal(false)}
                            onResubmit={() => toggleResubmitModal(true, formikProps)}
                            onResubmitCancel={() => toggleResubmitModal(false)}
                            onAmend={(values) => handleAmend(formikProps, values)}
                            toggleAmend={toggleCommentModal}/>
              </>}

              {shouldDisplayStateFormButtons && <>
                <StateFormButtons loading={loading}
                                  onAcceptClick={() => toggleAcceptModal()}
                                  onReturnClick={() => toggleCommentModal()}/>
                <StateFormModals onReturn={onReturn}
                                 returnModalIsOpen={commentModalIsOpen}
                                 onReturnCancel={toggleCommentModal}
                                 onAccept={() => toggleAcceptModal(true)}
                                 acceptModalIsOpen={acceptModalIsOpen}
                                 onAcceptCancel={() => toggleAcceptModal()}
                                 form={form}/>
              </>}

              {!isLocalUnitUser && <LocalUnitFormNavigator localUnitForm={form}
                                                           isStateUser={isStateUser}/>}
            </Form>
          </>;
        }}
      </Formik>
      }
    </div>
  );
};

export default withTabNav(Form4017, {tabs: PROPERTY_CLASSIFICATION_TABS});