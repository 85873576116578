import {useCallback, useEffect, useMemo, useState} from 'react';
import {Formik} from 'formik';
import {Button, Card, CardBody, CardHeader, Col, Container, Row, Table} from 'reactstrap';
import {useParams} from 'react-router-dom';
import {isNaN} from 'lodash';

import {BreadcrumbsNav, FormikInput, FormikSelect, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {recapComparisonApi} from '../../api/mega';
import * as messages from '../../messages';
import {formatDecimal, formatInteger} from '../../utils';
import {recapComparisonReportSchema} from '../../schemas/mega';
import {mega} from '../../types';
import {useUnsavedChangesWarning} from '../../hooks';
import {UnsavedChangesWarningModal} from '../../components/shared';

const classificationNames: {[classification: string]: string} = {
  '100': '101 Agricultural',
  '200': '201 Commercial',
  '300': '301 Industrial',
  '400': '401 Residential',
  '500': '501 Timber-Cutover',
  '600': '601 Developmental',
  'personal': 'Personal'
};
const studyTypes = ['N/A', 'Review', 'CWS', 'Review and CWS'];
const psdStudyTypes = ['S1', 'S2', 'NC'];

const RecapComparisonReport = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const {hasUnsavedChanges, setHasUnsavedChanges} = useUnsavedChangesWarning();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [comparisonReport, setComparisonReport] = useState<mega.RecapComparisonReport>(undefined);
  const [reportTotals, setReportTotals] = useState<mega.RecapComparisonReportTotals>(undefined);
  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: comparisonReport?.county?.displayNameWithType ?? '', active: true}
  ]), [
    year,
    countyId,
    comparisonReport
  ]);

  const mapValues = useCallback((recapReport: mega.RecapComparisonReport): mega.RecapComparisonReportRequest => {
    const blankClassification = {comments: null, studyType: null, psdStudyType: null};
    const recapComparisonReportRequest: mega.RecapComparisonReportRequest = {
      classifications: {
        '100': blankClassification,
        '200': blankClassification,
        '300': blankClassification,
        '400': blankClassification,
        '500': blankClassification,
        '600': blankClassification,
        personal: blankClassification
      }
    };

    if (recapReport) {
      const classificationsData = recapReport.classifications;
      Object.keys(classificationsData).forEach(classification => {
        recapComparisonReportRequest.classifications[classification] = {
          comments: classificationsData[classification].comments || '',
          studyType: classificationsData[classification].studyType || '',
          psdStudyType: classificationsData[classification].psdStudyType || ''
        };
      });

      recapComparisonReportRequest.classifications.personal.studyType = recapReport?.personalStudyType ?? '';
      recapComparisonReportRequest.classifications.personal.comments = recapReport?.personalComments ?? '';
    }

    return recapComparisonReportRequest;
  }, []);

  const handleSave = useCallback(async (recapComparisonReportRequest: mega.RecapComparisonReportRequest,
                                        afterSave?: () => void) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await recapComparisonApi.update(countyId, year, recapComparisonReportRequest);
      const comparisonReport = await recapComparisonApi.find(countyId, year);
      setComparisonReport(comparisonReport);
      setReportTotals(comparisonReport?.totals);

      showSuccessAlert(messages.REPORT_SAVE_SUCCESSFUL);
      setHasUnsavedChanges(false);

      if (afterSave) {
        afterSave();
      }
    } catch (error) {
      showErrorAlert(messages.REPORT_SAVE_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyId,
    loadingState,
    showSuccessAlert,
    showErrorAlert,
    setHasUnsavedChanges,
    year
  ]);

  const calculateValues = useCallback((recapComparisonReportRequest: mega.RecapComparisonReportRequest) => {
    if (comparisonReport && reportTotals) {
      const {...comparisonReportCopy} = ({...comparisonReport} as mega.RecapComparisonReport);
      const totals = {
        sampleSizeAssessedValue: 0,
        sampleSizeUnits: 0,
        sampleSizeParcels: 0,
        samplePercentAssessedValue: 0,
        samplePercentParcels: 0,
        samplePercentUnits: 0
      };

      Object.keys(comparisonReportCopy?.classifications ?? {}).forEach(classification => {
        const classificationData = comparisonReport.classifications[classification];
        const psdData = recapComparisonReportRequest.classifications[classification].psdStudyType === 'S1' ?
          classificationData.psd1YearStudy : classificationData.psd2YearStudy;
        const psdTrueCashValueProjection = psdData.trueCashValueProjection;
        const cedTrueCashValueProjection = comparisonReport.classifications[classification].ced.trueCashValueProjection;
        comparisonReportCopy.classifications[classification].percentDifference = psdTrueCashValueProjection !== 'N/A' && cedTrueCashValueProjection != null &&
        cedTrueCashValueProjection !== 0 && psdTrueCashValueProjection != null ?
          ((psdTrueCashValueProjection as number) / (cedTrueCashValueProjection as number)) - 1
          : 'N/A';
        totals.sampleSizeAssessedValue += psdData?.sampleSizeAssessedValue ?? 0;
        totals.sampleSizeParcels += psdData?.sampleSizeParcels ?? 0;
        totals.sampleSizeUnits += psdData?.sampleSizeUnits ?? 0;
      });

      if (reportTotals) {
        totals.samplePercentAssessedValue = reportTotals.startingAssessedValue !== 0 && reportTotals.startingAssessedValue !== null ?
          (totals.sampleSizeAssessedValue / reportTotals.startingAssessedValue) : 0;
        totals.samplePercentParcels = reportTotals.numberOfParcels !== 0 && reportTotals.numberOfParcels !== null ?
          (totals.sampleSizeParcels / reportTotals.numberOfParcels) : 0;
        totals.samplePercentUnits = reportTotals.numberOfUnitsWithClass !== 0 && reportTotals.numberOfUnitsWithClass ?
          (totals.sampleSizeUnits / reportTotals.numberOfUnitsWithClass) : 0;
      }
      return {...comparisonReportCopy, totals};
    }
  }, [
    comparisonReport,
    reportTotals
  ]);

  useEffect(() => {
    const loadRecapComparisonReport = async () => {
      try {
        const comparisonReport = await recapComparisonApi.find(countyId, year);
        setComparisonReport(comparisonReport);
        setReportTotals(comparisonReport?.totals);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadRecapComparisonReport();
  }, [countyId, year, showErrorAlert]);

  const calculateRows = useCallback((comments: string) => {
    let rows = 3;
    if (comments && comments.split('\n').length > 3) {
      rows = comments.split('\n').length;
    }
    return rows;
  }, []);

  const handlePdfClick = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      await recapComparisonApi.openPdf(countyId, year, showErrorAlert);
    } catch {
      showErrorAlert(messages.VIEW_PDF_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyId,
    year,
    showErrorAlert,
    loadingState
  ]);

  const mappedValues = useMemo(() => {
    return mapValues(comparisonReport);
  }, [mapValues, comparisonReport]);

  return <Container fluid className="RecapComparisonReport">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && comparisonReport && <>
      <Row className="mb-3">
        <Col md="6">
          <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
        </Col>
        <Col md="6" className="d-flex justify-content-end">
          <Button color="primary"
                  disabled={!comparisonReport.pdf}
                  onClick={handlePdfClick}>
            View PDF
          </Button>
        </Col>
      </Row>
      <Formik enableReinitialize={true}
              validationSchema={recapComparisonReportSchema}
              initialValues={{...mappedValues}}
              onSubmit={async () => null}
              validateOnMount={true}>
        {(formikProps) => {
          const calculatedValues = calculateValues(formikProps.values);
          return <>
            <Card className="mb-4">
              <CardHeader>
                Recap Comparison Report
              </CardHeader>
              <CardBody>
                <div className="mt-1 mb-4 SplitTable table-fixed">
                  <Table bordered responsive>
                    <thead className="text-center bg-light">
                      <tr>
                        <th colSpan={4} className="bg-light align-middle text-primary">CED L-4018 Real Property</th>
                        <th colSpan={9} className="bg-light align-middle border-left-3 text-primary">CED L-4018 Real Property Recap</th>
                        <th colSpan={10} className="bg-light-teal align-middle border-left-3 text-primary">PSD Field Staff Countywide Sales Study</th>
                      </tr>
                    </thead>
                    <thead className="text-center bg-light">
                      <tr>
                        <th colSpan={4} className="bg-light"/>
                        <th className="align-middle border-left-3 bg-light"/>
                        <th colSpan={3} className="align-middle border-left-3 bg-light text-primary">CED Sample Size</th>
                        <th colSpan={4} className="align-middle border-left-3 bg-light text-primary">CED Sample %</th>
                        <th className="align-middle border-left-3 bg-light text-primary">
                          {Number(year) + 1}
                        </th>
                        <th className="border-left-3 bg-light-teal text-primary"/>
                        <th colSpan={3} className="bg-light-teal align-middle border-left-3 text-primary">PSD Sample Size</th>
                        <th colSpan={4} className="bg-light-teal align-middle border-left-3 text-primary">PSD Sample %</th>
                        <th className="bg-light-teal align-middle border-left-3 text-primary">
                          {Number(year) + 1}
                        </th>
                        <th className="bg-light-teal border-left-3 text-primary"/>
                      </tr>
                      <tr>
                        <th className="align-middle text-primary">Real Property Class</th>
                        <th className="align-middle text-primary">Starting Assessed Value</th>
                        <th className="align-middle text-primary"># Units With Class</th>
                        <th className="align-middle text-primary"># of Parcels</th>
                        <th className="align-middle border-left-3 text-primary">CED Study Types</th>
                        <th className="align-middle border-left-3 text-primary">Assessed Value</th>
                        <th className="align-middle text-primary">Units</th>
                        <th className="align-middle text-primary">Parcels</th>
                        <th className="align-middle border-left-3 text-primary">Assessed Value</th>
                        <th className="align-middle text-primary">Units</th>
                        <th className="align-middle text-primary">Parcels</th>
                        <th className="align-middle text-primary">Class</th>
                        <th className="align-middle border-left-3  text-primary">TCV Projection</th>
                        <th className="align-middle text-nowrap border-left-3 bg-light-teal text-primary">Study Type Code</th>
                        <th className="align-middle border-left-3 bg-light-teal text-primary">Assessed Value</th>
                        <th className="align-middle bg-light-teal text-primary">Units</th>
                        <th className="align-middle bg-light-teal text-primary">Parcels</th>
                        <th className="align-middle border-left-3 bg-light-teal text-primary">Assessed Value</th>
                        <th className="align-middle bg-light-teal text-primary">Units</th>
                        <th className="align-middle bg-light-teal text-primary">Parcels</th>
                        <th className="align-middle bg-light-teal text-primary">Class</th>
                        <th className="align-middle border-left-3 bg-light-teal text-primary">TCV Projection</th>
                        <th className="align-middle border-left-3 bg-light-teal text-primary">% Difference</th>
                      </tr>
                    </thead>
                    <tbody>
                      {calculatedValues &&
                        ['100', '200', '300', '400', '500', '600'].map((classification) => {
                          const classificationData = comparisonReport.classifications[classification];
                          const {percentDifference} = calculatedValues.classifications[classification];
                          const psdStudy = formikProps.values.classifications[classification].psdStudyType === 'S1' ?
                            classificationData.psd1YearStudy : classificationData.psd2YearStudy;
                          return <tr key={classification}>
                            <td className="text-left align-middle text-nowrap text-primary font-weight-bold">
                              {classificationNames[classification]}
                            </td>
                            <td className="text-center align-middle">
                              {formatInteger(classificationData.startingAssessedValue)}
                            </td>
                            <td className="text-center align-middle">
                              {formatInteger(classificationData.numberOfUnitsWithClass)}
                            </td>
                            <td className="text-center align-middle">
                              {formatInteger(classificationData.numberOfParcels)}
                            </td>
                            <td className="text-center align-middle border-left-3">
                              {classificationData.cedStudyTypes}
                            </td>
                            <td className="text-center align-middle border-left-3">
                              {formatInteger(classificationData.ced.sampleSizeAssessedValue)}
                            </td>
                            <td className="text-center align-middle">
                              {formatInteger(classificationData.ced.sampleSizeUnits)}
                            </td>
                            <td className="text-center align-middle">
                              {formatInteger(classificationData.ced.sampleSizeParcels)}
                            </td>
                            <td className="text-center align-middle border-left-3">
                              {formatDecimal(classificationData.ced.samplePercentAssessedValue, 2, true)}
                            </td>
                            <td className="text-center align-middle">
                              {formatDecimal(classificationData.ced.samplePercentUnits, 0, true)}
                            </td>
                            <td className="text-center align-middle">
                              {formatDecimal(classificationData.ced.samplePercentParcels, 2, true)}
                            </td>
                            <td className="text-center align-middle">
                              {formatDecimal(classificationData.ced.samplePercentClass, 2, true)}
                            </td>
                            <td className="text-center align-middle border-left-3">
                              {formatInteger(classificationData.ced.trueCashValueProjection)}
                            </td>
                            <td className="border-left-3 bg-light-teal">
                              <FormikSelect ariaLabel={`${classificationNames[classification]} PSD Study Type`}
                                            formGroupClass="mb-0"
                                            onChange={() => setHasUnsavedChanges(true)}
                                            name={`classifications[${classification}].psdStudyType`}>
                                <option value={''}>Select</option>
                                {psdStudyTypes.map((studyType) =>
                                  <option key={studyType} value={studyType}>
                                    {studyType}
                                  </option>)}
                              </FormikSelect>
                            </td>
                            <td className="text-center align-middle border-left-3 bg-light-teal">
                              {formatInteger(psdStudy.sampleSizeAssessedValue)}
                            </td>
                            <td className="text-center align-middle bg-light-teal">
                              {formatInteger(psdStudy.sampleSizeUnits)}
                            </td>
                            <td className="text-center align-middle bg-light-teal">
                              {formatInteger(psdStudy.sampleSizeParcels)}
                            </td>
                            <td className="text-center align-middle border-left-3 bg-light-teal">
                              {formatDecimal(psdStudy.samplePercentAssessedValue, 2, true)}
                            </td>
                            <td className="text-center align-middle bg-light-teal">
                              {formatDecimal(psdStudy.samplePercentUnits, 0, true)}
                            </td>
                            <td className="text-center align-middle bg-light-teal">
                              {formatDecimal(psdStudy.samplePercentParcels, 2, true)}
                            </td>
                            <td className="text-center align-middle bg-light-teal">
                              {formatDecimal(psdStudy.samplePercentClass, 2, true)}
                            </td>
                            <td className="text-center align-middle border-left-3 bg-light-teal">
                              {formatInteger(psdStudy.trueCashValueProjection)}
                            </td>
                            <td className="text-center align-middle border-left-3 bg-light-teal">
                              {!isNaN(percentDifference) && formatDecimal(percentDifference, 1, true)}
                            </td>
                          </tr>;
                        })
                      }
                    </tbody>
                    {reportTotals && calculatedValues && calculatedValues.totals && <tfoot>
                      <tr key="totals" className="bg-light font-weight-bold">
                        <td className="text-left align-middle text-dark bg-light">Totals</td>
                        <td className="text-center align-middle bg-light">
                          {formatInteger(reportTotals.startingAssessedValue)}
                        </td>
                        <td className="text-center align-middle bg-light">
                          {formatInteger(reportTotals.numberOfUnitsWithClass)}
                        </td>
                        <td className="text-center align-middle bg-light">
                          {formatInteger(reportTotals.numberOfParcels)}
                        </td>
                        <td className="border-left-3 bg-light"/>
                        <td className="text-center align-middle border-left-3 bg-light">
                          {formatInteger(reportTotals.ced.sampleSizeAssessedValue)}
                        </td>
                        <td className="text-center align-middle bg-light">
                          {formatInteger(reportTotals.ced.sampleSizeUnits)}
                        </td>
                        <td className="text-center align-middle bg-light">
                          {formatInteger(reportTotals.ced.sampleSizeParcels)}
                        </td>
                        <td className="text-center align-middle border-left-3 bg-light">
                          {formatDecimal(reportTotals.ced.samplePercentAssessedValue, 2, true)}
                        </td>
                        <td className="text-center align-middle bg-light">
                          {formatDecimal(reportTotals.ced.samplePercentUnits, 0, true)}
                        </td>
                        <td className="text-center align-middle bg-light">
                          {formatDecimal(reportTotals.ced.samplePercentParcels, 2, true)}
                        </td>
                        <td className="bg-light"/>
                        <td className="text-center align-middle border-left-3 bg-light">
                          {formatInteger(reportTotals.ced.trueCashValueProjection)}
                        </td>
                        <td className="border-left-3 bg-light-teal"/>
                        <td className="text-center align-middle border-left-3 bg-light-teal">
                          {formatInteger(calculatedValues.totals.sampleSizeAssessedValue)}
                        </td>
                        <td className="text-center align-middle bg-light-teal">
                          {formatInteger(calculatedValues.totals.sampleSizeUnits)}
                        </td>
                        <td className="text-center align-middle bg-light-teal">
                          {formatInteger(calculatedValues.totals.sampleSizeParcels)}
                        </td>
                        <td className="text-center align-middle border-left-3 bg-light-teal">
                          {formatDecimal(calculatedValues.totals.samplePercentAssessedValue, 2, true)}
                        </td>
                        <td className="text-center align-middle bg-light-teal">
                          {formatDecimal(calculatedValues.totals.samplePercentUnits, 0, true)}
                        </td>
                        <td className="text-center align-middle bg-light-teal">
                          {formatDecimal(calculatedValues.totals.samplePercentParcels, 2, true)}
                        </td>
                        <td className="bg-light-teal"/>
                        <td className="border-left-3 bg-light-teal"/>
                        <td className="border-left-3 bg-light-teal"/>
                      </tr>
                    </tfoot>
                    }
                  </Table>
                </div>
                <Table bordered responsive>
                  <thead className="bg-light">
                    <tr>
                      <th className="text-left align-middle text-primary">Class</th>
                      <th className="text-center align-middle text-primary">Study Type</th>
                      <th className="text-center align-middle text-primary">Property Services Division Field Staff Summary of Findings and Recommendations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(mappedValues.classifications).map((classification) => {
                        return <tr className="align-middle" key={classification}>
                          <td className="text-left text-nowrap text-primary font-weight-bold">
                            {classificationNames[classification]}
                          </td>
                          <td>
                            <FormikSelect ariaLabel={`${classificationNames[classification]} Study Type`}
                                          formGroupClass="mb-0"
                                          onChange={() => setHasUnsavedChanges(true)}
                                          name={`classifications[${classification}].studyType`}>
                              <option value={''}>Select</option>
                              {studyTypes.map((studyType) => {
                                return <option key={studyType} value={studyType}>
                                  {studyType}
                                </option>;
                              })}
                            </FormikSelect>
                          </td>
                          <td>
                            <FormikInput name={`classifications[${classification}].comments`}
                                         maxLength="2000"
                                         disableFloatingLabel={true}
                                         type="textarea"
                                         rows={calculateRows(formikProps.values?.classifications?.[classification]?.comments ?? '')}
                                         formGroupClass="mb-0"
                                         ariaLabel={`${classificationNames[classification]} Comments`}
                                         onChange={() => setHasUnsavedChanges(true)}/>
                          </td>
                        </tr>;
                      })
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>

            <Row>
              <Col className="d-flex justify-content-end">
                <Button color="success"
                        disabled={loadingState.processing || !hasUnsavedChanges || !formikProps.isValid}
                        onClick={() => handleSave(formikProps.values)}>
                  Save
                </Button>
              </Col>
            </Row>

            <UnsavedChangesWarningModal onSave={(afterSave) => handleSave(formikProps.values, afterSave)}/>
          </>;
        }}
      </Formik>
    </>}
  </Container>;
};

export default RecapComparisonReport;