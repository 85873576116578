import {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, CustomInput, Row} from 'reactstrap';
import {Formik} from 'formik';
import {parseISO} from 'date-fns';

import {ConfirmationModal, FormikRadioGroup, TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {
  filterPropertiesByClassification,
  PROPERTY_CLASSIFICATION_TABS,
  salesStudyPeriods,
  sumObjects
} from '../../utils';
import {
  Form4015ContentionTable,
  Form4015Period,
  Form4015PropertyModal,
  Form4015TotalsTable
} from '../../components/forms/form4015';
import {
  CommentModal,
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  LocalUnitFormNavigator,
  NoPropertiesModal,
  StateFormButtons,
  StateFormModals,
  UnsavedChangesWarningModal
} from '../../components/shared';
import {AmendmentRequest, Comment, forms, LocalUnitFormDto, ReturnRequest, StudyPeriodTotals} from '../../types';
import {FormStatus} from '../../enum';
import {useUnsavedChangesWarning} from '../../hooks';

const mapStudyYears = (studyYears: forms.ClassificationStudyYears) => {
  const studyYearsMap: forms.ClassificationStudyYears = {};
  Object.entries(studyYears || {})
    .forEach(([key, value]) => studyYearsMap[key] = value ? value.toString() : null);
  return studyYearsMap;
};

const tabs = [
  ...PROPERTY_CLASSIFICATION_TABS,
  {value: 'contention', displayValue: 'Contention'}
];

type Props = {
  form: LocalUnitFormDto
  loading: boolean
  isStateUser: boolean
  onReturn: (returnRequest: ReturnRequest) => void
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.LocalUnitFormData) => void
  onSubmitToCounty: (localUnitFormData: forms.LocalUnitFormData) => void
  onSubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onResubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onSave: (localUnitFormData: forms.LocalUnitFormData, afterSave?: () => void) => Promise<void>
  onAccept: () => void
  onSendToReview: (localUnitFormData: forms.LocalUnitFormData) => void
  isLocalUnitUser: boolean
  isCountyUser: boolean
  classificationStudyYears: forms.ClassificationStudyYears
  setClassificationStudyYears: (classificationStudyYears: forms.ClassificationStudyYears) => void
}

type PropertiesFilteredByPeriod = {
  [key: string]: forms.Form4015PropertyDto[]
}

const Form4015 = ({
                    form,
                    loading,
                    isStateUser,
                    onReturn,
                    onAmend,
                    onSubmit,
                    onSubmitToCounty,
                    onResubmit,
                    onSave,
                    onAccept,
                    onSendToReview,
                    isCountyUser,
                    isLocalUnitUser,
                    classificationStudyYears,
                    setClassificationStudyYears
                  }: Props) => {
  const {setHasUnsavedChanges} = useUnsavedChangesWarning();

  const form4015Dto = useMemo(() => {
    // Use latest form submission data if available for state users
    if (isStateUser && form.latestSubmissionData !== null) {
      return form.latestSubmissionData as forms.Form4015Dto;
    } else if (isLocalUnitUser && form.localUnitData !== null) {
      return form.localUnitData as forms.Form4015Dto;
    } else {
      return form.data as forms.Form4015Dto;
    }
  }, [form, isLocalUnitUser, isStateUser]);

  const [noPropertiesModalIsOpen, setNoPropertiesModalIsOpen] = useState(false);
  const [propertyModalIsOpen, setPropertyModalIsOpen] = useState(false);
  const [propertyModalOperation, setPropertyModalOperation] = useState('Add');
  const [selectedProperty, setSelectedProperty] = useState<forms.Form4015PropertyDto | undefined>(undefined);
  const [noPropertiesSelections, setNoPropertiesSelections] = useState(form4015Dto.noPropertiesInClassification);
  const [nextId, setNextId] = useState(form4015Dto.properties.length + 1);
  const [propertyDeleteModalIsOpen, setPropertyDeleteModalIsOpen] = useState(false);
  const [submitFormModalIsOpen, setSubmitFormModalIsOpen] = useState(false);
  const [resubmitFormModalIsOpen, setResubmitFormModalIsOpen] = useState(false);
  const [acceptFormModalIsOpen, setAcceptFormModalIsOpen] = useState(false);
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const {selectedTab} = useTabNav();
  const [contentionModalIsOpen, setContentionModalIsOpen] = useState(false);
  const [contentionCommentModalIsOpen, setContentionCommentModalIsOpen] = useState(false);
  const [isContendAndCommentModalIsOpen, setIsContendAndCommentModalIsOpen] = useState(false);
  const [isSendToReviewModalOpen, setIsSendToReviewModalOpen] = useState(false);

  const [properties, setProperties] = useState(form4015Dto.properties);

  const filteredProperties = useMemo(() => filterPropertiesByClassification(properties), [properties]);

  const is2YearStudy = useMemo(() => selectedTab !== undefined &&
      selectedTab !== 'contention' &&
      classificationStudyYears &&
      classificationStudyYears[selectedTab] !== undefined &&
      Number(classificationStudyYears[selectedTab]) === 2,
    [
      selectedTab,
      classificationStudyYears
    ]);

  const studyPeriods = useMemo(() => salesStudyPeriods(form.year, is2YearStudy), [form.year, is2YearStudy]);

  const hasBeenSubmittedToState = useMemo(() => {
    return form?.formSubmissions?.filter(formSubmission => formSubmission.wasSubmittedByCounty)?.length > 0;
  }, [form]);

  const shouldDisplayStateFormButtons = useMemo(() => {
    return isStateUser &&
      form.status !== 'IN_PROGRESS' &&
      hasBeenSubmittedToState;
  }, [form.status, isStateUser, hasBeenSubmittedToState]);

  const shouldDisplayFormActionButtons = useMemo(() => !isStateUser, [isStateUser]);

  const showReadOnlyView = useMemo(() => {
    return isStateUser || form.locked;
  }, [form, isStateUser]);

  const showSendToLocalUnitButton = useMemo(() => {
    return !isLocalUnitUser &&
      !showReadOnlyView &&
      !form.hasBeenSentToReview && (
        form.status === FormStatus.NOT_STARTED ||
        form.status === FormStatus.IN_PROGRESS
      );
  }, [
    isLocalUnitUser,
    showReadOnlyView,
    form.hasBeenSentToReview,
    form.status
  ]);

  const getSavableData = useCallback(() => {
    return {
      type: 'FORM_4015' as const,
      noPropertiesInClassification: noPropertiesSelections,
      classificationStudyYears: classificationStudyYears || {},
      properties
    };
  }, [
    classificationStudyYears,
    noPropertiesSelections,
    properties
  ]);

  const handleSave = useCallback((afterSave?: () => void) => {
    return onSave(getSavableData(), afterSave);
  }, [
    getSavableData,
    onSave
  ]);

  const handleSendToReview = useCallback(() => {
    onSendToReview(getSavableData());
    setIsSendToReviewModalOpen(false);
  }, [
    getSavableData,
    onSendToReview
  ]);

  const handleNoPropertiesSelection = useCallback(() => {
    // If it was selected let the user unselect with no prompts, otherwise display the confirmation modal
    if (selectedTab && noPropertiesSelections[selectedTab]) {
      const updatedClassificationsBooleanMap = ({
        ...noPropertiesSelections,
        [selectedTab as string]: false
      }) as forms.ClassificationsBooleanMap;
      setNoPropertiesSelections(updatedClassificationsBooleanMap);
      setHasUnsavedChanges(true);
    } else {
      setNoPropertiesModalIsOpen(true);
    }
  }, [noPropertiesSelections, selectedTab, setHasUnsavedChanges]);

  const isDuplicate = useCallback((property: forms.Form4015PropertyDto) => {
    return filteredProperties[selectedTab as string].filter(p => p.propertyNumber === property.propertyNumber).length > 1;
  }, [filteredProperties, selectedTab]);

  const togglePropertyModal = useCallback((operation: 'Add' | 'Edit', property?: forms.Form4015PropertyDto) => {
    if (!propertyModalIsOpen) {
      setPropertyModalIsOpen(true);
      setPropertyModalOperation(operation);
      if (operation === 'Add') {
        setSelectedProperty(undefined);
      } else if (operation === 'Edit' && property) {
        setSelectedProperty(property);
      }
    } else {
      // The conditional blocks handle if a successful add, successful edit, or if a cancel happened.
      setHasUnsavedChanges(true);
      if (operation === 'Add' && property) {
        property.id = nextId;
        property.isDuplicate = isDuplicate(property);
        property.wasAddedByLocalUnit = isLocalUnitUser;

        setProperties([...properties, property]);
        setPropertyModalIsOpen(false);
        setNextId(nextId + 1);
      } else if (operation === 'Edit' && property) {
        const index = properties.findIndex((item: forms.Form4015PropertyDto) => item.id === property?.id);
        property.isDuplicate = isDuplicate(property);
        property.wasAddedByLocalUnit = isLocalUnitUser;
        if (index !== -1) {
          properties[index] = property;
        }
        setProperties([...properties]);
        setPropertyModalIsOpen(false);
      } else {
        setPropertyModalIsOpen(false);
      }
    }
  }, [
    properties,
    nextId,
    propertyModalIsOpen,
    setHasUnsavedChanges,
    isDuplicate,
    isLocalUnitUser
  ]);

  const toggleNoPropertiesModal = useCallback((confirmNoProperties = false) => {
    if (noPropertiesModalIsOpen) {
      if (confirmNoProperties) {
        const noPropertiesSelectionsCopy = {...noPropertiesSelections, [selectedTab as string]: true};
        const filteredPropertiesCopy = {...filteredProperties, [selectedTab as string]: []};
        let properties: forms.Form4015PropertyDto[] = [];
        Object.keys(filteredPropertiesCopy).forEach(
          key => properties = [...properties, ...filteredPropertiesCopy[key]]
        );

        setProperties(properties);
        setNoPropertiesSelections(noPropertiesSelectionsCopy);
        setNoPropertiesModalIsOpen(false);
        const classificationStudyYearsCopy = {...classificationStudyYears} as forms.ClassificationStudyYears;
        classificationStudyYearsCopy[selectedTab as string] = null;
        setClassificationStudyYears(classificationStudyYearsCopy);
        setHasUnsavedChanges(true);
      } else {
        setNoPropertiesModalIsOpen(false);
      }
    } else {
      setNoPropertiesModalIsOpen(true);
    }
  }, [
    classificationStudyYears,
    filteredProperties,
    noPropertiesModalIsOpen,
    noPropertiesSelections,
    selectedTab,
    setClassificationStudyYears,
    setHasUnsavedChanges
  ]);

  const toggleAcceptFormModal = useCallback((confirmAccept = false) => {
    if (confirmAccept) {
      onAccept();
    }
    setAcceptFormModalIsOpen(!acceptFormModalIsOpen);
  }, [acceptFormModalIsOpen, onAccept]);

  const toggleResubmitModal = useCallback((confirmResubmit = false) => {
    if (confirmResubmit) {
      onResubmit(getSavableData());
    }
    setResubmitFormModalIsOpen(!resubmitFormModalIsOpen);
  }, [getSavableData, onResubmit, resubmitFormModalIsOpen]);

  const toggleCommentModal = useCallback(() => setCommentModalIsOpen(!commentModalIsOpen), [commentModalIsOpen]);

  const toggleSubmitModal = useCallback((confirmSubmit = false) => {
    const submitForm = isLocalUnitUser ? onSubmitToCounty : onSubmit;
    if (confirmSubmit) {
      submitForm(getSavableData());
    }
    setSubmitFormModalIsOpen(!submitFormModalIsOpen);
  }, [getSavableData, onSubmitToCounty, onSubmit, submitFormModalIsOpen, isLocalUnitUser]);

  const filterParcelsInBetween = useCallback((properties: forms.Form4015PropertyDto[],
                                              begin: Date,
                                              end: Date) => {
    return properties.filter(item => {
      const itemDate = item.date instanceof Date ? item.date : parseISO(item.date);
      return new Date(itemDate) >= begin && new Date(itemDate) <= end;
    });
  }, []);

  const filterParcelsByDuration = useCallback((properties: forms.Form4015PropertyDto[],
                                               periodDurationKey: number) => {
    const period = studyPeriods[periodDurationKey];
    return filterParcelsInBetween(properties, period.begin, period.end);
  }, [studyPeriods, filterParcelsInBetween]);

  const parcelsByStudyPeriod = useMemo((): PropertiesFilteredByPeriod => {
    const filteredPropertiesCurrentClassification = selectedTab !== 'contention' ?
      filteredProperties[selectedTab as string]
        .map(property => {
          property.isDuplicate = isDuplicate(property);
          return property;
        }) : [];

    return {
      1: filterParcelsByDuration(filteredPropertiesCurrentClassification, 1),
      2: filterParcelsByDuration(filteredPropertiesCurrentClassification, 2),
      3: filterParcelsByDuration(filteredPropertiesCurrentClassification, 3),
      4: filterParcelsByDuration(filteredPropertiesCurrentClassification, 4),
      5: filterParcelsByDuration(filteredPropertiesCurrentClassification, 5),
      year1: filterParcelsInBetween(filteredPropertiesCurrentClassification, studyPeriods[1].begin, studyPeriods[4].end),
      year2: filterParcelsInBetween(filteredPropertiesCurrentClassification, studyPeriods[4].begin, studyPeriods[5].end)
    };
  }, [
    filteredProperties,
    selectedTab,
    studyPeriods,
    filterParcelsByDuration,
    isDuplicate,
    filterParcelsInBetween
  ]);

  const handleSetStudyLength = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setHasUnsavedChanges(true);
    setClassificationStudyYears({
      ...classificationStudyYears,
      [selectedTab as string]: e.target.value
    });
  }, [
    classificationStudyYears,
    selectedTab,
    setHasUnsavedChanges,
    setClassificationStudyYears
  ]);

  const toggleDeleteModal = useCallback((property?: forms.Form4015PropertyDto) => {
    if (propertyDeleteModalIsOpen) {
      // If property has a value while the modal is open then attempt to delete it
      if (property) {
        setHasUnsavedChanges(true);
        const index = properties.findIndex((item: forms.Form4015PropertyDto) => item.id === property.id);
        const propertiesCopy = [...properties];
        propertiesCopy.splice(index, 1);
        setProperties(propertiesCopy);
      }

      setPropertyDeleteModalIsOpen(false);
    } else if (property) {
      setSelectedProperty(property);
      setPropertyDeleteModalIsOpen(true);
    }
  }, [
    properties,
    propertyDeleteModalIsOpen,
    setHasUnsavedChanges
  ]);

  const handleAmend = useCallback((amendmentRequest: AmendmentRequest) => {
    onAmend(amendmentRequest, getSavableData());
  }, [
    getSavableData,
    onAmend
  ]);

  const isFormValid = useMemo(() => {
    return Object.keys(noPropertiesSelections)
      .every(classification => noPropertiesSelections[classification] || filteredProperties[classification].length > 0);
  }, [
    filteredProperties,
    noPropertiesSelections
  ]);

  const isSubmitDisabled = useMemo(() => {
    const isLocalUnitAmendDisabled = form.hasBeenSentToReview &&
      isLocalUnitUser &&
      form.formSubmissions.length > 0;
    return !isFormValid || loading || showReadOnlyView || isLocalUnitAmendDisabled;
  }, [
    isFormValid,
    loading,
    showReadOnlyView,
    form,
    isLocalUnitUser
  ]);

  const isSendToReviewDisabled = useMemo(() => {
    return loading ||
      showReadOnlyView ||
      !isFormValid ||
      form.status === FormStatus.SENT_TO_REVIEW_TO_LOCAL_UNIT ||
      form.status === FormStatus.SUBMITTED ||
      form.status === FormStatus.ACCEPTED;
  }, [
    isFormValid,
    loading,
    showReadOnlyView,
    form
  ]);

  const isLocalUnitEditDisabled = useMemo(() => {
    return isLocalUnitUser && (
      loading ||
      showReadOnlyView ||
      form.status === FormStatus.IN_PROGRESS ||
      form.status === FormStatus.SUBMITTED_TO_COUNTY ||
      form.status === FormStatus.SUBMITTED ||
      form.status === FormStatus.RETURNED ||
      form.status === FormStatus.ACCEPTED);
  }, [
    loading,
    showReadOnlyView,
    form,
    isLocalUnitUser
  ]);

  const calculatePeriodTotals = useCallback((properties: forms.Form4015PropertyDto[]): StudyPeriodTotals => {
    const assessedValue = sumObjects(properties, 'assessedValue');
    const salesPrice = sumObjects(properties, 'salesPrice');
    const adjustedSalesPrice = sumObjects(properties, 'adjustedSalesPrice');

    return {
      numberOfProperties: properties.length,
      assessedValue,
      salesPrice,
      adjustedSalesPrice,
      ratio: adjustedSalesPrice !== 0 ? assessedValue / adjustedSalesPrice : 0
    };
  }, []);

  const studyPeriodTotals = useMemo(() => {
    return Object.keys(studyPeriods).map((key) => ({...calculatePeriodTotals(parcelsByStudyPeriod[key])}));
  }, [
    calculatePeriodTotals,
    parcelsByStudyPeriod,
    studyPeriods
  ]);

  const totals1And2Year = useMemo(() => ({
    year1: calculatePeriodTotals(parcelsByStudyPeriod.year1),
    year2: calculatePeriodTotals(parcelsByStudyPeriod.year2)
  }), [
    calculatePeriodTotals,
    parcelsByStudyPeriod
  ]);

  const handleContendToggle = useCallback((property: forms.Form4015PropertyDto) => {
    if (contentionModalIsOpen) {
      setHasUnsavedChanges(true);

      const propertiesCopy = [...properties];
      const index = propertiesCopy.findIndex((item: forms.Form4015PropertyDto) => item.id === property.id);
      const updatedValue = !propertiesCopy[index].contended;
      // Clear comments if the property is no longer contended
      if (!updatedValue) {
        propertiesCopy[index].contentionComments = '';
      }
      propertiesCopy[index].contended = updatedValue;

      setProperties(propertiesCopy);
      setContentionModalIsOpen(false);
      setSelectedProperty(undefined);
    } else {
      setSelectedProperty(property);
      setContentionModalIsOpen(true);
    }
  }, [
    properties,
    setHasUnsavedChanges,
    contentionModalIsOpen
  ]);

  const handleContendCommentsEdit = useCallback((property: forms.Form4015PropertyDto, comments: string) => {
    const index = properties.findIndex((item: forms.Form4015PropertyDto) => item.id === property.id);
    properties[index].contentionComments = comments;
    setProperties(properties);
    setContentionModalIsOpen(false);
  }, [
    properties
  ]);

  const handleContendAndCommentToggle = useCallback((property: forms.Form4015PropertyDto, comments?: string) => {
    if (isContendAndCommentModalIsOpen) {
      setHasUnsavedChanges(true);

      const index = properties.findIndex((item: forms.Form4015PropertyDto) => item.id === property.id);
      properties[index].contentionComments = comments;
      properties[index].contended = true;

      setIsContendAndCommentModalIsOpen(false);
      setSelectedProperty(undefined);
      setProperties(properties);

    } else {
      setSelectedProperty(property);
      setIsContendAndCommentModalIsOpen(true);
    }
  }, [
    properties,
    setHasUnsavedChanges,
    isContendAndCommentModalIsOpen
  ]);

  const initialValues = useMemo(() => {
    return {
      classificationStudyYears: mapStudyYears(classificationStudyYears)
    };
  }, [
    classificationStudyYears
  ]);

  const selectedStudyPeriods = useMemo(() => {
    // If user selects a 2-year study only display the first 4 time periods, otherwise display all 5
    return is2YearStudy ? [1, 2, 3, 4] : [1, 2, 3, 4, 5];
  }, [
    is2YearStudy
  ]);

  const totalsStudyPeriods = useMemo(() => ({
    1: {
      begin: studyPeriods[1].begin,
      end: studyPeriods[4].end,
      omitted: false
    },
    2: {
      begin: studyPeriods[4].begin,
      end: studyPeriods[5].end,
      omitted: is2YearStudy
    }
  }), [
    is2YearStudy,
    studyPeriods
  ]);

  const submitButtonText = useMemo(() => {
    if (!form) {
      return 'Submit';
    }

    const {
      isOverdue,
      formSubmissions,
      formReturns,
      status,
      hasBeenSentToReview
    } = form as LocalUnitFormDto;
    const formHasBeenReviewed = status === FormStatus.ACCEPTED || formReturns.length >= 1;
    const showAmendForLocalUnit = hasBeenSentToReview && isLocalUnitUser && formSubmissions.length > 0;
    const showAmendForCounty = hasBeenSubmittedToState && (isOverdue || formHasBeenReviewed);
    const showResubmit = hasBeenSubmittedToState && !isOverdue && !isLocalUnitUser;

    if (showAmendForLocalUnit || showAmendForCounty) {
      return 'Amend';
    } else if (showResubmit) {
      return 'Resubmit';
    } else if (isLocalUnitUser) {
      return 'Send to County';
    } else {
      return 'Submit';
    }
  }, [
    form,
    isLocalUnitUser,
    hasBeenSubmittedToState
  ]);

  return (
    <div className="Form4015">
      <Card className="mb-3">
        <FormHeader form={form}/>
        <CardHeader className="nav-tabs-header">
          <TabNav/>
        </CardHeader>
        <CardBody>
          {!showReadOnlyView && selectedTab !== 'contention' && <Row className="mb-2 no-properties-row">
            <Col xs="12" md="6">
              <CustomInput type="switch"
                           id="noParcelsSwitch"
                           name="noParcels"
                           label="No Parcels in Class or Form needed"
                           aria-label="No Parcels in Class or Form needed"
                           onChange={handleNoPropertiesSelection}
                           checked={noPropertiesSelections[selectedTab as string] as boolean}
                           disabled={loading || isLocalUnitEditDisabled}/>
            </Col>
            <Col xs="12" md="6" className="d-flex justify-content-end">
              {!noPropertiesSelections[selectedTab as string] &&
                <Button color="primary"
                        onClick={() => togglePropertyModal('Add')}
                        disabled={isLocalUnitEditDisabled}>
                  Add Parcel
                </Button>}
            </Col>
          </Row>}
          {!noPropertiesSelections[selectedTab as string] && selectedTab !== 'contention' && <Row>
            <Col xs="12" md="12">
              <Formik initialValues={initialValues}
                      onSubmit={async () => null}>
                {(_) => (
                  <FormikRadioGroup name={`classificationStudyYears['${selectedTab}']`}
                                    inline={true}
                                    disabled={showReadOnlyView || isLocalUnitEditDisabled}
                                    onChange={handleSetStudyLength}
                                    radioButtons={[
                                      {labelText: '1 Year Study', value: '1', ariaLabel: '1 Year Study'},
                                      {labelText: '2 Year Study', value: '2', ariaLabel: '2 Year Study'}
                                    ]}
                  />)}
              </Formik>
            </Col>
          </Row>}
          {selectedTab === 'contention' &&
            <Form4015ContentionTable onEdit={handleContendCommentsEdit}
                                     onContend={handleContendToggle}
                                     showReadOnlyView={!isLocalUnitUser || isLocalUnitEditDisabled}
                                     setIsModalOpen={setContentionCommentModalIsOpen}
                                     isModalOpen={contentionCommentModalIsOpen}
                                     properties={properties}/>}

          {!noPropertiesSelections[selectedTab as string] && selectedTab && selectedTab !== 'contention' &&
            <>
              <Form4015TotalsTable studyPeriods={studyPeriods}
                                   studyPeriodTotals={studyPeriodTotals}
                                   headerText="6 Month Period"/>

              <Form4015TotalsTable studyPeriods={totalsStudyPeriods}
                                   studyPeriodTotals={is2YearStudy ? [totals1And2Year.year1] : [totals1And2Year.year1, totals1And2Year.year2]}
                                   headerText="1 / 2 Year"/>

              {selectedStudyPeriods.map((studyPeriodNumber) => <>
                <Form4015Period onEdit={(property) => togglePropertyModal('Edit', property)}
                                onDelete={toggleDeleteModal}
                                isContendDisabled={isLocalUnitUser ? isLocalUnitEditDisabled : true}
                                showReadOnlyView={showReadOnlyView}
                                studyPeriod={studyPeriods[studyPeriodNumber]}
                                onContend={handleContendToggle}
                                onContendAndCommentToggle={handleContendAndCommentToggle}
                                isLocalUnitUser={isLocalUnitUser}
                                isCountyUser={isCountyUser}
                                properties={parcelsByStudyPeriod[studyPeriodNumber]}/>
              </>)}
            </>}
        </CardBody>
      </Card>
      <FormHistoryTable items={form.formHistory}/>

      {!showReadOnlyView && selectedProperty && <ConfirmationModal isOpen={contentionModalIsOpen}
                                                                   title="Contend Parcel"
                                                                   confirmButtonText="Yes"
                                                                   cancelButtonText="No"
                                                                   confirmCallback={() => handleContendToggle(selectedProperty)}
                                                                   cancelCallback={() => setContentionModalIsOpen(false)}>
        {selectedProperty.contended && <>
          Are you sure you wish to remove <span className="text-danger">{selectedProperty.propertyNumber}</span> from contention?
        </>}
        {!selectedProperty.contended && <>
          Are you sure you wish to contend <span className="text-danger">{selectedProperty.propertyNumber}</span> from the {form.description} Form for {form.localUnitDisplayName}?
        </>}
      </ConfirmationModal>}

      {!showReadOnlyView && selectedProperty && !selectedProperty.contended &&
        <CommentModal isOpen={isContendAndCommentModalIsOpen}
                      modalTitle="Contend Parcel"
                      onToggle={() => setIsContendAndCommentModalIsOpen(false)}
                      onSubmit={(comment: Comment) => handleContendAndCommentToggle(selectedProperty, comment.comment)}
                      defaultValue={selectedProperty.contentionComments || ''}
                      renderAfterForm={<Row>
                        <Col className="small text-muted">
                          Contention comments will display on the Contention tab accessible at the top of this form.
                        </Col>
                      </Row>}
                      confirmButtonText="Contend"
                      cancelButtonText="Cancel">
          <p>
            Provide a comment for contending <span className="text-danger">{selectedProperty.propertyNumber}</span> from the Sales Study List Form for {form.localUnitDisplayName}.
          </p>
        </CommentModal>}

      {shouldDisplayFormActionButtons && <>
        <UnsavedChangesWarningModal onSave={handleSave}/>
        <FormActionButtons submitDisabled={isSubmitDisabled}
                           saveDisabled={loading || showReadOnlyView || isLocalUnitEditDisabled}
                           onSave={handleSave}
                           submitButtonText={submitButtonText}
                           onToggleCommentModal={toggleCommentModal}
                           onToggleResubmitModal={() => toggleResubmitModal(false)}
                           onToggleSubmitModal={() => toggleSubmitModal(false)}
                           showSendToLocalUnitButton={showSendToLocalUnitButton}
                           showSubmitButton={!showReadOnlyView && !isLocalUnitEditDisabled}
                           isSendToReviewDisabled={isSendToReviewDisabled}
                           onSendToReview={() => setIsSendToReviewModalOpen(true)}/>
      </>}

      {!isStateUser && <>
        <Form4015PropertyModal isOpen={propertyModalIsOpen}
                               operation={propertyModalOperation as ('Add' | 'Edit')}
                               classificationFilter={selectedTab as string}
                               property={selectedProperty}
                               equalizationYear={form.year}
                               onToggle={togglePropertyModal}/>

        <NoPropertiesModal onToggle={toggleNoPropertiesModal}
                           isOpen={noPropertiesModalIsOpen}
                           selectedClassificationFilter={selectedTab as string}
                           title="Confirm No Parcels or Form needed"
                           bodyTextEnding="class or form needed"/>

        {selectedProperty && <ConfirmationModal isOpen={propertyDeleteModalIsOpen}
                                                size="lg"
                                                title="Delete Parcel"
                                                confirmButtonColor="primary"
                                                confirmButtonText="Yes"
                                                cancelButtonText="No"
                                                confirmCallback={() => toggleDeleteModal(selectedProperty)}
                                                cancelCallback={() => toggleDeleteModal()}>
          <p>
            Are you sure you want to delete <span className="text-danger">{selectedProperty.propertyNumber}</span> from the {form.description} Form for {form.localUnitDisplayName}?
          </p>
        </ConfirmationModal>}

        <ConfirmationModal isOpen={isSendToReviewModalOpen}
                           size="lg"
                           title="Send to Local Unit"
                           confirmButtonColor="success"
                           confirmButtonText="Yes"
                           cancelButtonText="Cancel"
                           confirmCallback={handleSendToReview}
                           cancelCallback={() => setIsSendToReviewModalOpen(false)}>
          <p>
            Are you sure you want to send the {form.description} Form for <span className="text-danger">{form.localUnitDisplayName}</span> to the local unit for review?
          </p>
        </ConfirmationModal>

        <FormModals form={form}
                    resubmitFormModalIsOpen={resubmitFormModalIsOpen}
                    submitFormModalIsOpen={submitFormModalIsOpen}
                    amendModalIsOpen={commentModalIsOpen}
                    onSubmit={() => toggleSubmitModal(true)}
                    onCancelSubmit={toggleSubmitModal}
                    onResubmit={() => toggleResubmitModal(true)}
                    onResubmitCancel={toggleResubmitModal}
                    onAmend={handleAmend}
                    isLocalUnit4015={isLocalUnitUser}
                    toggleAmend={toggleCommentModal}/>
      </>}

      {shouldDisplayStateFormButtons && <>
        <StateFormButtons loading={loading}
                          onReturnClick={toggleCommentModal}
                          onAcceptClick={() => toggleAcceptFormModal()}/>
        <StateFormModals onReturn={onReturn}
                         returnModalIsOpen={commentModalIsOpen}
                         onReturnCancel={toggleCommentModal}
                         onAccept={() => toggleAcceptFormModal(true)}
                         acceptModalIsOpen={acceptFormModalIsOpen}
                         onAcceptCancel={() => toggleAcceptFormModal()}
                         form={form}/>
      </>}

      {!isLocalUnitUser && <LocalUnitFormNavigator localUnitForm={form}
                                                   isStateUser={isStateUser}/>}
    </div>
  );
};

export default withTabNav(Form4015, {tabs});