import {Outlet} from 'react-router-dom';

import {Alerts, MiFooter, SkipNav, useUserContext} from '@reasoncorp/kyber-js';

import './font-awesome-icons';
import {MegAppBar} from './components';
import {PortalContextProvider, UnsavedChangesProvider} from './contexts';

const AppLayout = () => {
  const {currentUser, loadingUser, permissions} = useUserContext();

  return <UnsavedChangesProvider>
    <PortalContextProvider>
      <SkipNav/>
      <header>
        <MegAppBar currentUser={currentUser}
                   isAdmin={permissions.isAdmin}/>
      </header>
      {!loadingUser &&
        <main id="content" role="main">
          <Outlet/>
        </main>}
      <MiFooter/>
      <Alerts/>
    </PortalContextProvider>
  </UnsavedChangesProvider>;
};

export default AppLayout;