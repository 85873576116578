import {ReactNode, useCallback, useEffect, useState} from 'react';
import {UnsavedChangesWarningContext} from './UnsavedChangesWarningContext';
import {useBeforeUnload, useLocation} from 'react-router-dom';

type Props = {
  children: ReactNode
}

const UnsavedChangesProvider = ({
                                  children
                                }: Props) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setHasUnsavedChanges(false);
    setIsWarningModalOpen(false);
  }, [location]);

  const handleBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
    if (hasUnsavedChanges) {
      e.preventDefault();
      const message = 'Are you sure you want to go without saving changes?';

      if (message && e) {
        e.returnValue = message;
      }

      return message;
    }
  }, [
    hasUnsavedChanges
  ]);

  // I don't recommend doing this elsewhere.
  // This allows removing the beforeunload event listener outside in places we can't reach with the
  // warning context, specifically in the SsoApiRestHelper when the user is redirected to login.
  useEffect(() => {
    // @ts-ignore
    window.removeBeforeUnloadEventListener = () => {
      console.debug('Removing onbeforeunload event listener');
      window.removeEventListener('beforeunload', handleBeforeUnload, {capture: true});
    };
  }, [
    handleBeforeUnload
  ]);

  useBeforeUnload(handleBeforeUnload, {capture: true});

  return <UnsavedChangesWarningContext.Provider value={{
    hasUnsavedChanges,
    setHasUnsavedChanges,
    isWarningModalOpen,
    setIsWarningModalOpen
  }}>
    {children}
  </UnsavedChangesWarningContext.Provider>;
};

export default UnsavedChangesProvider;