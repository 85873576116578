import {useCallback, useMemo, useState} from 'react';
import {CustomInput} from 'reactstrap';

import {ButtonIcon, CustomTable} from '@reasoncorp/kyber-js';

import {Comment, forms} from '../../../types';
import * as messages from '../../../messages';
import {formatDate} from '../../../utils';
import {CommentModal} from '../../shared';

type Props = {
  properties: forms.Form4015PropertyDto[]
  showReadOnlyView: boolean
  onEdit: (property: forms.Form4015PropertyDto, comments: string) => void
  setIsModalOpen: (isModalOpen: boolean) => void
  isModalOpen: boolean
  onContend: (property: forms.Form4015PropertyDto) => void
}

const Form4015ContentionTable = ({
                                   properties,
                                   showReadOnlyView,
                                   setIsModalOpen,
                                   isModalOpen,
                                   onEdit,
                                   onContend
                                 }: Props) => {
  const [selectedProperty, setSelectedProperty] = useState<forms.Form4015PropertyDto | undefined>(undefined);
  const contendedProperties = useMemo(() => {
    return properties.filter(property => property.contended);
  }, [
    properties
  ]);

  const handleEditToggle = useCallback((property?: forms.Form4015PropertyDto) => {
    if (!selectedProperty) {
      setIsModalOpen(true);
      setSelectedProperty(property);
    } else {
      setIsModalOpen(false);
      setSelectedProperty(undefined);
    }
  }, [
    setIsModalOpen,
    setSelectedProperty,
    selectedProperty
  ]);

  const renderRow = useMemo(() => (property: forms.Form4015PropertyDto) => {
    const {
      id,
      date,
      propertyNumber,
      grantor,
      grantee,
      contended,
      contentionComments
    } = property;
    return <tr key={id}>
      <td className="align-middle text-center">
        {formatDate(date)}
      </td>
      <td className="align-middle text-center">
        {propertyNumber}
      </td>
      <td className="align-middle text-center">
        {grantor}
      </td>
      <td className="align-middle text-center">
        {grantee}
      </td>
      <td className="align-middle">
        {contentionComments}
      </td>
      {!showReadOnlyView &&
        <td className="align-middle text-center cursor-pointer">
          <ButtonIcon className="text-primary"
                      ariaLabel="Edit Contention Comments"
                      title="Edit Contentions Comments"
                      icon="cog"
                      onClick={() => handleEditToggle(property)}
          />
        </td>}
      {!showReadOnlyView && <td className="align-middle text-center cursor-pointer">
        <CustomInput type="switch"
                     id="contend"
                     name="contend"
                     className="text-primary"
                     ariaLabel="Mark Parcel as Contention"
                     title="Mark Parcel as Contention"
                     aria-label="Mark Parcel as Contention"
                     checked={contended}
                     onChange={() => onContend(property)}
                     disabled={showReadOnlyView}/>
      </td>}
    </tr>;
  }, [
    showReadOnlyView,
    handleEditToggle,
    onContend
  ]);

  const handleSubmit = useCallback((comment: string) => {
    if (selectedProperty) {
      onEdit(selectedProperty, comment);
      setSelectedProperty(undefined);
    }
  }, [
    onEdit,
    selectedProperty
  ]);

  const tableProps = useMemo(() => ({
    headers: [{
      title: 'Date',
      className: 'text-nowrap text-center',
      sortKey: 'date'
    }, {
      title: 'Parcel Number',
      className: 'text-nowrap text-center',
      sortKey: 'propertyNumber'
    }, {
      title: 'Grantor',
      className: 'text-nowrap text-center',
      sortKey: 'grantor'
    }, {
      title: 'Grantee',
      className: 'text-nowrap text-center',
      sortKey: 'grantee'
    }, {
      title: 'Comments',
      className: 'text-nowrap'
    }, {
      title: 'Edit Comments',
      className: 'text-nowrap text-center',
      hide: showReadOnlyView
    }, {
      title: 'Contend',
      className: 'text-nowrap text-center',
      hide: showReadOnlyView
    }],
    noResultsMessage: messages.NO_PARCELS_ADDED,
    items: contendedProperties,
    renderRow,
    initialSort: [{
      sortKey: 'date',
      direction: 'desc' as const
    }],
    chainSort: true,
    paginatorConfig: {
      perPage: 100,
      recordName: 'parcels',
      allowShowAll: true
    }
  }), [
    contendedProperties,
    showReadOnlyView,
    renderRow
  ]);

  return <>
    {selectedProperty && <CommentModal isOpen={isModalOpen}
                                       modalTitle="Contention Comments"
                                       onToggle={() => handleEditToggle(undefined)}
                                       onSubmit={(comment: Comment) => handleSubmit(comment.comment)}
                                       defaultValue={selectedProperty.contentionComments || ''}
                                       confirmButtonText="Save"
                                       cancelButtonText="Cancel">
      <p>Reason for Contention</p>
    </CommentModal>}
    <CustomTable {...tableProps} />
  </>;
};

export default Form4015ContentionTable;