import {createContext} from 'react';

export type UnsavedChangesWarningContextType = {
  hasUnsavedChanges: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  isWarningModalOpen: boolean
  setIsWarningModalOpen: (isWarningModalOpen: boolean) => void
}

export const UnsavedChangesWarningContext = createContext<UnsavedChangesWarningContextType>({
  hasUnsavedChanges: false,
  setHasUnsavedChanges: (_: boolean) => null,
  isWarningModalOpen: false,
  setIsWarningModalOpen: (_: boolean) => null
});